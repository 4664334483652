import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class SidebarService {
  toggled = false;
  _hasBackgroundImage = false;
  menus = [
    {
      title: "Raporlar",
      type: "header",
    },
    {
      title: "Günlük Operasyon Raporu",
      icon: "check",
      active: false,
      reportName: "DailyReport",
      // badge: {
      //   text: '27',
      //   class: 'badge-success'
      // },
      submenus: [
        // {
        //   title: 'Gün. Operasyon Özet Rap.',
        //   reportID: 1,
        //   icon: 'check',
        //   url: "DailyReportSummary/"
        // },
        // {
        //   title: 'Bekleyen Siparişler',
        // },
        // // {
        // //   title: 'Hazırlanan Siparişler',
        // //   badge: {
        // //     text: '15',
        // //     class: 'badge-warning'
        // //   },
        // // },
        // {
        //   title: 'Tamamlanan Siparişler',
        // }
        // ,
        // {
        //   title: 'Teslim Edilen Siparişler',
        // }
      ],
    },
    {
      title: "Satış Maliyet Raporu",
      icon: "fact_check",
      active: false,
      reportName: "CostReport",
      // badge: {
      //   text: '27',
      //   class: 'badge-success'
      // },
    },
    {
      title: "Servis Tipine Göre Satışlar Raporu",
      icon: "fact_check",
      active: false,
      reportName: "SalesReport",
      // badge: {
      //   text: '27',
      //   class: 'badge-success'
      // },
    },
    {
      title: "Envanter Raporu",
      icon: "fact_check",
      active: false,
      reportName: "InventoryReport",
    },
    {
      title: "Fatura Raporu",
      icon: "fact_check",
      active: false,
      reportName: "InvoiceReport",
    },
    {
      title: "Fire Zayi Personel Malzeme Çıkış Raporu",
      icon: "fact_check",
      active: false,
      reportName: "WastageStaffReport",
      // badge: {
      //   text: '27',
      //   class: 'badge-success'
      // },
    },
    {
      title: "Şube Analiz Raporu",
      icon: "fact_check",
      active: false,
      reportName: "branchAnalysisReport",
      // badge: {
      //   text: '27',
      //   class: 'badge-success'
      // },
    },
    {
      title: "KDV Ciro Raporu",
      icon: "fact_check",
      active: false,
      reportName: "VatTurnoverReport",
      // badge: {
      //   text: '27',
      //   class: 'badge-success'
      // },
    },
    
  ];
  constructor() {}

  toggle() {
    this.toggled = !this.toggled;
  }

  getSidebarState() {
    return this.toggled;
  }

  setSidebarState(state: boolean) {
    this.toggled = state;
  }

  getMenuList() {
    return this.menus;
  }

  get hasBackgroundImage() {
    return this._hasBackgroundImage;
  }

  set hasBackgroundImage(hasBackgroundImage) {
    this._hasBackgroundImage = hasBackgroundImage;
  }
}
