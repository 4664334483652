import {
  Component,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { Router } from "@angular/router";
import { DxFormComponent, DxSelectBoxComponent } from "devextreme-angular";
import { DxiItemComponent } from "devextreme-angular/ui/nested";
import { Branch, IdNameType, OnoffModel } from "src/app/models/global-model";
import { GlobalService } from "src/app/services/global.service";
import { ReportService } from "src/app/services/report.service";

@Component({
  selector: "app-request-form",
  templateUrl: "./request-form.component.html",
  styleUrls: ["./request-form.component.scss"],
})
export class RequestFormComponent implements OnInit {
  branchList: Branch[] = [];
  onOffData: OnoffModel[] = [];
  costSummaryParam: OnoffModel[] = [];
  includeTaxTypes: IdNameType[] = [];
  consolidateTypes: IdNameType[] = [];
  parameterTypes: IdNameType[] = [];
  reportLevelTypes: IdNameType[] = [];
  goruntulenmeTypes: IdNameType[] = [];
  hammaddeTypes: IdNameType[] = [];
  konsolideTypesInventory: any[] = [];
  invoiceTypes: any[] = [];
  recordTypes: any[] = [];
  reportTypes: any[] = [];
  useTypes: any[] = [];
  countingTypes: any[] = [];
  formCollapsed = false;
  hideForm = false;

  today = new Date();

  @ViewChildren("wareHouse") wareHouse: QueryList<DxiItemComponent>;
  @ViewChildren("productList") productList: QueryList<DxiItemComponent>;

  constructor(
    public rs: ReportService,
    public glb: GlobalService,
    private nav: Router
  ) {
    this.glb.mainRequestData;
    this.getOnOffData();
    this.costSummaryParam = this.glb.getSummaryParam();
    this.consolidateTypes = this.glb.getconsolidateTypes();
    this.countingTypes = this.glb.getcountingTypes();
    this.parameterTypes = this.glb.getParameterTypes();
    this.invoiceTypes = this.glb.getinvoiceTypes();
    this.recordTypes = this.glb.getRecordTypes();
    this.konsolideTypesInventory = this.glb.getkonsolideForInventory();
    this.getWareHouse = this.getWareHouse.bind(this);
    this.getProducts = this.getProducts.bind(this);
    this.reportTypes = this.glb.getReportTypes();
    this.reportLevelTypes = this.glb.getreportLevelTypes();
    this.goruntulenmeTypes = this.glb.getGoruntulenmeTypes();
    this.hammaddeTypes = this.glb.gethammadeTypes();
    this.includeTaxTypes = this.glb.getIncludeTaxTypes();
    this.useTypes = this.glb.getUseTypes();
  }

  getOnOffData() {
    this.onOffData = this.glb.getOnOff();
  }

  getBranchList() {}

  salesRaportField = [
    "selectedGroup",
    "serviceTypes",
    "stock_code",
    "consolidated",
  ]; // Servis Tipine göre
  costReportField = [
    // Satış Maliyet Raporu
    "selectedGroup",
    "isSummary",
    "includeTax",
    "stock_code",
    "licanceNo",
  ];

  inVoiceReportFields = [
    //startdate,
    //enddate,
    "invoiceType",
    "recortType",
    "accountCode",
    "selectedGroup",
    "warehouse",
    "param",
    "stock_code",
    // licanceNo
  ];
  inventoryFields = [
    //startdate,
    //enddate,
    "warehouse",
    "vatInclude",
    "selectedGroup",
    "counting",
    "isConsolidated",
    "stock_code",
    "isSummary",
    // licanceNo
  ];

  wastageStaffFields = [
    "warehouse",
    "selectedGroup",
    "useType",
    // "reportType",
    "stock_code",
    "reportLevel", // seviye
    "rawMaterial", // hammadde
    // "showDetail", // Görüntülenme
    "reasonType", // Sebep
  ];

  checkReport(field) {
    // this.rs.filtredProducts = this.rs.products.filter(x=>x.P_TYPE = 'S')
    switch (this.glb.selectedRepoort) {
      case "CostReport":
        return this.costReportField.includes(field);
      case "SalesReport":
        // this.rs.filtredProducts = this.rs.products.filter(x=>x.P_TYPE = 'P')
        return this.salesRaportField.includes(field);
      case "WastageStaffReport":
        return this.wastageStaffFields.includes(field);
      case "InvoiceReport":
        return this.inVoiceReportFields.includes(field);
      case "InventoryReport":
        return this.inventoryFields.includes(field);
      default:
        return false;
    }
  }

  getWareHouse(event: any) {
    if (this.wareHouse) {
      if (this.wareHouse.first.editorOptions.value)
        this.wareHouse.first.editorOptions.value = null;
    }

    if (
      this.isBranchHasMultiple &&
      event.previousValue?.join(",") != event.value?.join(",")
    ) {
      let lastValue = event.value?.join(",");
      // Selected Report is InvoiceReport or InventoryReport

      if (event.value) {
        this.rs.getWareHouses(lastValue);
      } else {
        this.rs.getWareHouses("-1");
      }
    }
  }

  get isBranchHasMultiple() {
    return ["InvoiceReport", "InventoryReport"].includes(
      this.glb.selectedRepoort
    );
  }

  getReportData(form: DxFormComponent) {
    console.log(this.glb.selectedRepoort);
    if (form.instance.validate().isValid) {
      this.getReport();
    }
  }

  getReport() {
    console.log(this.glb.mainRequestData);
    this.glb.loadingText = "Rapor Yükleniyor...";
    this.glb.loadingVisible = true;

    switch (this.glb.selectedRepoort) {
      case "CostReport":
        return this.rs.getSalesCostReport().then((o) => {
          this.glb.loadingVisible = false;
          this.nav.navigateByUrl("layout/cost-report");
        });

      case "SalesReport":
        this.glb.consiledType = this.glb.mainRequestData.consolidated;
        return this.rs.getSalesReportByServiceType().then((o) => {
          this.glb.loadingVisible = false;
          this.nav.navigateByUrl("layout/sales-report-service-type");
        });

      case "InvoiceReport":
        return this.rs.getInvoiceList().then((o) => {
          this.glb.loadingVisible = false;
          this.nav.navigateByUrl("layout/invoice-report");
        });
      case "WastageStaffReport":
        return this.rs.getWastageStaffReport().then((o) => {
          this.glb.loadingVisible = false;
          this.nav.navigateByUrl("layout/wastage-staff-report");
        });
      case "VatTurnoverReport":
        return this.rs.getVatTurnoverReport().then((o) => {
          this.glb.loadingVisible = false;
          this.nav.navigateByUrl("layout/vat-turnover-report");
        });
      case "InventoryReport":
        return this.rs.getInventoryReport().then((o) => {
          this.glb.loadingVisible = false;
          this.nav.navigateByUrl("layout/inventory-report");
        });
      case "branchAnalysisReport":
        return this.rs.getBranchAnalysisReport().then((o) => {
          this.glb.loadingVisible = false;
          this.nav.navigateByUrl("layout/branch-analysis-report");
        });
      default:
        this.rs.getOperationReport().then((o) => {
          this.glb.loadingVisible = false;
          this.nav.navigateByUrl("layout/operation-report");
        });
        break;
    }

    // if (this.glb.selectedRepoort == "CostReport") {
    // } else if (this.glb.selectedRepoort == "SalesReport") {
    // } else {
    // }
  }

  getProducts(event) {
    if (!this.isBranchHasMultiple) {
      return;
    }

    if (this.productList) {
      this.productList.first.editorOptions.value = null;
    }
    if (event.previousValue?.join(",") != event.value?.join(",")) {
      if (event.value?.length > 0) {
        this.rs.getProductList(event.value.join(","));
      } else {
        this.rs.getProductList("-1");
      }
    }
  }

  toggleForm() {
    this.formCollapsed = !this.formCollapsed;
    setTimeout(
      () => {
        this.hideForm = !this.hideForm;
      },
      !this.hideForm ? 1500 : 500
    );
  }

  ngOnInit(): void {}
}
