<nav id="sidebar" class="sidebar" style="background-color: #4C52BC!important;border-color :#4C52BC;color:#ffffff;" [ngClass]="{'sidebar-bg' : hasBackgroundImage()}">
  <a id="show-sidebar" (click)="toggleSidebar()" class="btn btn-sm btn-dark toggled" style="background-color: #4C52BC!important;border-color: #4C52BC;">
      <i class="fas fa-bars"></i>
    </a>
<div class="sidebar-content">
    <div class="sidebar-header">
      <a >Kerzz Data Analiz</a>
      <div id="close-sidebar" >
       <a (click)="toggleSidebar()"> <i class="fas fa-times"></i></a>
      </div>
    </div>

    <div class="sidebar-profile">
      <div class="user-pic">
        <img class="img-responsive img-rounded" src="assets/images/user.jpg" alt="User picture">
      </div>
    
      <div class="user-info">
        <span class="user-name">{{this.us.user.name}}  
        </span>     
        <span class="user-status" style="float:left;">          
          <span>{{this.us.activeLicance.brand}}</span>
        </span>
        <span class="material-icons" (click)="selectLicance()">
          store_mall_directory
          </span>
      </div>
    </div>
    <!-- <div class="sidebar-search">
      <div>
        <div class="input-group">
          <input type="text" class="form-control search-menu" placeholder="Arama...">
          <div class="input-group-append">
            <span class="input-group-text">
              <i class="fa fa-search" aria-hidden="true"></i>
            </span>
          </div>
        </div>
      </div>
    </div> -->
    <div class="sidebar-menu">
      <ul>
        <li *ngFor="let menu of filtereddMenus" [ngClass]="{'active': menu.active , 'sidebar-dropdown':menu.type === 'dropdown' , 'header-menu':menu.type === 'header'}">
          <span style="color:#ffffff;" *ngIf="menu.type === 'header'">{{menu.title}}</span>
          <a style="cursor: pointer;" (click)="getReport(menu.reportName)"  *ngIf="menu.type !== 'header'" (click)='toggle(menu)'>
            <span class="material-icons" style="font-size: 17px;
            margin-top: 7px;">
              {{menu.icon}}
              </span>
            <!-- <i class="{{menu.icon}}"></i> -->
            <span style="font-size:14px">{{menu.title}}</span>
            <span *ngIf="menu.badge" class="badge badge-pill" [ngClass]="menu.badge.class">{{menu.badge.text}}</span>
          </a>


          <div *ngIf="menu.type === 'dropdown'" class="sidebar-submenu" [@slide]="getState(menu)" >
            <ul>                 
              <li *ngFor="let submenu of menu.submenus" style="cursor: pointer;" [ngClass]="{'active': false , 'sidebar-dropdown':submenu.type === 'dropdown' }">   
                <div *ngIf="submenu.type === 'dropdown'" class="sidebar-submenu" [@slide]="getState(submenu)" >
                  <a style="cursor: pointer;" >
                    <span class="material-icons" style="font-size: 17px;
                    margin-top: 7px;">
                      {{menu.icon}}
                      </span>
                    <span >{{submenu.title}}</span>                 
                  </a>
                  <a *ngIf="menu.type !== 'header'" (click)='toggle(menu)'>
                    <span class="material-icons" style="font-size: 17px;
                    margin-top: 7px;">
                      {{menu.icon}}
                      </span>
                      <span style="cursor: pointer;" >{{menu.title}}</span>
                      <span *ngIf="menu.badge" class="badge badge-pill" [ngClass]="menu.badge.class">{{menu.badge.text}}</span>
                    </a>
                </div>
                <div style="cursor: pointer;"  *ngIf="submenu.type !== 'dropdown'"  >
                  <a (click)="getReport(submenu.url)">
                    <span>{{submenu.title}}</span>   
                    <span *ngIf="submenu.badge" class="badge badge-pill" [ngClass]="submenu.badge.class">{{submenu.badge.text}}</span>
                  </a>
                </div>

                                           
                </li>
                </ul>
              </div>           
        </li>
      </ul>
    </div>
  
</div>
<div class="sidebar-footer">  
  <div (click)="logout()" style="cursor: pointer;font-size: 12px;margin-left: 42%;">
    <span>
      Çıkış
      </span>
    <span class="material-icons" style="font-size: 17px;
    margin-top: 7px;">
      login
      </span>
  </div>
</div>
</nav>

<script>
$("#close-sidebar").click(function() {
$(".page-wrapper").removeClass("toggled");
});
$("#show-sidebar").click(function() {
$(".page-wrapper").addClass("toggled");
});
</script>
