import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { ReportService } from 'src/app/services/report.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
mailAddress: string = ""
password : string = ""
resetPasswordPopupVisible : boolean = false;
public theBoundCallback: Function;
  constructor(public us : UserService,public glb : GlobalService,private nav : Router,public rs :ReportService) { }


  login(args)
  {
    if (this.mailAddress == '' || this.password == '')
    {
      this.glb.pushToast('Kullanıcı Bilgilerinizi Giriniz','warning',2000)
      return
    }
    
    console.log(this.mailAddress,this.password)
    this.glb.loadingText = "Bilgileriniz Kontrol Ediliyor."
    this.glb.loadingVisible = true;
    setTimeout(() => {
      this.glb.loadingVisible = false;
      this.us.login(this.mailAddress,this.password).then(o => {
        this.glb.loadingVisible = false;
        if (o == "ok") {      
          if (localStorage.getItem('activeStore') != null) {                        
            this.us.setActiveLicance(localStorage.getItem('activeStore'))
            this.rs.getBranchList()
            this.rs.getBranchList()
          }
          else {
            this.glb.licancePopupVisible = true;
          }  
          this.nav.navigateByUrl('layout')
        }
      })
    }, 2000);
  }

  theCallback()
  {
    this.resetPasswordPopupVisible = false;
  }


  forgetPassword()
  {
      this.resetPasswordPopupVisible = true;
  }

  ngOnInit(): void {
    this.theBoundCallback = this.theCallback.bind(this);
  }

}
