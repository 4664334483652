<div class="row" style="margin-bottom: 20px">
  <div class="col-12">
    <button
      (click)="exportExcel()"
      style="float: right"
      type="button"
      class="btn btn-success"
    >
      <span class="material-icons"> get_app </span>
    </button>
    <!-- <button
      (click)="exportToPDF()"
      style="float: right; margin-right: 5px"
      type="button"
      class="btn btn-primary"
    >
      <span class="material-icons"> picture_as_pdf </span>
    </button> -->
  </div>
</div>

<div id="contentToConvert">
  <dx-data-grid
    #dataGrid
    [columnAutoWidth]="true"
    [columnHidingEnabled]="false"
    [scrolling]="{ showScrollbar: 'always' }"
    [dataSource]="rs.inventoryReport"
    [columnHidingEnabled]="true"
    [customizeColumns]="customizeColumns"
  >
    <dxo-column-fixing [enabled]="false"></dxo-column-fixing>

    <dxo-column-chooser [enabled]="true" mode="select"> </dxo-column-chooser>
  </dx-data-grid>
</div>

<!-- <div class="container shadow" style="margin-bottom: 30px; display: none">
  <div class="col-12">
    <table
      class="table table-hover"
      style="margin-bottom: 20px"
      id="contentToConvert"
    >
      <thead>
        <tr>
          <th style="color: #4c52bc" scope="col">Depo Adı</th>
          <th style="color: #4c52bc" scope="col">Grup Adı</th>
          <th style="color: #4c52bc" scope="col">Stok Kodu</th>
          <th style="color: #4c52bc" scope="col">Stok Adı</th>
          <th style="color: #4c52bc" scope="col">Birim Adı</th>
          <th style="color: #4c52bc" scope="col">Başlangıç</th>
          <th style="color: #4c52bc" scope="col">Giren</th>
          <th style="color: #4c52bc" scope="col">Çıkan</th>
          <th style="color: #4c52bc" scope="col">Kalan</th>
          <th style="color: #4c52bc" scope="col">Kalması Gereken</th>
          <th style="color: #4c52bc" scope="col">Fark</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let item of rs.inventoryReport; let c = index">
          <td>{{ item.DEPO_ADI }}</td>
          <td>{{ item.GrupAdi }}</td>
          <td>{{ item.stokkodu }}</td>
          <td>{{ item.stokadi }}</td>
          <td>{{ item.birimadi }}</td>
          <td>{{ item.baslangic }}</td>
          <td>{{ item.giren }}</td>
          <td>{{ item.cikan }}</td>
          <td>{{ item.KALAN }}</td>
          <td>{{ item.KalmasiGereken }}</td>
          <td>{{ item.fark }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div> -->
