import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from './global.service';
import { timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConnectorService {

  constructor(private http: HttpClient, public glb: GlobalService) { }
    reportServiceUrl = "https://report-service.kerzz.com:4100" 
//  reportServiceUrl = "https://127.0.0.1:4100" 
  
  apiKey = "YWxpLTEyMzQ1MTEyNDU2NTQzMg"
  token = ""
  serviceRequest(path, postData, _token = "") {    
    this.token = _token
     let url = `${this.reportServiceUrl}${path}`
     // return this.http.post(url, postData, this.getHeaders())
 
     return this.http.post(url, 
         postData, this.getHeaders()).pipe(timeout(30000))
 
   }
 
   serviceRequestPromise(path, postData, _token = "") {
     return new Promise((resolve, reject) => {
 
       if (!navigator.onLine) {
         this.glb.pushToast("No Internet Connection", "error", 3000)
         reject("offline")
         return
       }
 
       this.token = _token
       let url = `${this.reportServiceUrl}${path}`
       this.http.post(url, postData, this.getHeaders()).subscribe(o => {
         resolve(o)
       }, e => {
 
 
         if (e.status == 0) {
           this.glb.pushToast("Hay Aksi! Bir Sorun var, Bir süre sonra tekrar deneyin", "error", 3000)
         } else if (e.status == 404 || e.status == 401) {
           reject(e.error.message)
         } else {
           console.log("orwiService",e)
           this.glb.pushToast(e.error.message, "error", 3000)
         }
       })
 
     })
 
   }
 
   getHeaders() {
     let httpOptions = {
       headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'apikey': this.apiKey, 'token': this.token} )
     }
     return httpOptions
   }
}
