<div class="row" style="margin-bottom: 20px;">
    <div class="col-12">
      <button (click)="exportExcel()" style="float: right;" type="button" class="btn btn-success">
        <span class="material-icons">
          get_app
          </span>
      </button>
      <button (click)="exportToPDF()" style="float: right; margin-right: 5px;" type="button" class="btn btn-primary">
        <span class="material-icons">
          picture_as_pdf
          </span>
      </button>
    </div>
  </div>


<div class="container shadow" style="margin-bottom: 30px;">
    <div class="col-12">
        <table class="table table-hover" style="margin-bottom: 20px;" id="contentToConvert">
            <thead>
                <tr>
                    <th style="color:#4C52BC" scope="col">Şube Adı</th>
                    <th style="color:#4C52BC" scope="col">İskonto Nedeni</th>
                    <th style="color:#4C52BC" scope="col">Toplam</th>
                    <th style="color:#4C52BC" scope="col">Sayı</th>
                    <th style="color:#4C52BC" scope="col">Oralama</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of this.rs.discountReport;let c=index">
                    <td>{{item.SUBE_ADI}}</td>
                    <td><a (click)="getDiscountDetail(item.ISKONTO_NEDENI)"><span style="text-decoration: underline;cursor: pointer;">{{item.ISKONTO_NEDENI}}</span></a></td>
                    <td>{{item.TOPLAM | currency}}</td>
                    <td>{{item.SAYI}}</td>
                    <td style="padding-left: 30px;">{{item.TOPLAM / item.SAYI | currency}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div> 
