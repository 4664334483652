<div class="row" style="margin-bottom: 20px;">
    <div class="col-12">
      <button (click)="exportExcel()" style="float: right;" type="button" class="btn btn-success">
        <span class="material-icons">
          get_app
          </span>
      </button>
      <button (click)="exportToPDF()" style="float: right; margin-right: 5px;" type="button" class="btn btn-primary">
        <span class="material-icons">
          picture_as_pdf
          </span>
      </button>
    </div>
  </div>


<div class="container shadow" style="margin-bottom: 30px;">
    <div class="col-12" style="overflow-x:auto;">
        <table class="table table-hover" style="margin-bottom: 20px;" id="contentToConvert">
            <thead>
                <tr>                    
                    <th style="color:#4C52BC" scope="col">Tarih</th>
                    <th style="color:#4C52BC" scope="col">Sube</th>
                    <th style="color:#4C52BC" scope="col">Adisyon No</th>
                    <th style="color:#4C52BC" scope="col">Neden</th>
                    <th style="color:#4C52BC" scope="col">İkram Zamanı</th>
                    <th style="color:#4C52BC" scope="col">Masa Açan</th>  
                    <th style="color:#4C52BC" scope="col">Garson</th>       
                    <th style="color:#4C52BC" scope="col">Ürün Adı</th>             
                    <th style="color:#4C52BC" scope="col">Miktarı</th>  
                    <th style="color:#4C52BC" scope="col">Birim</th>  
                    <th style="color:#4C52BC" scope="col">İkram Toplam</th> 
                    <th style="color:#4C52BC" scope="col">Toplam</th>  
                    <!-- <th style="color:#4C52BC" scope="col">Maliyet</th>   
                    <th style="color:#4C52BC" scope="col">Yekün</th>   -->
                    <th style="color:#4C52BC" scope="col">Açıklama</th>                     
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of this.rs.giftDetailData;let c=index">
                    <td>{{item.Tarih | date :  "dd.MM.yyyy h:mm"}}</td>
                    <td>{{item.SUBE_ADI}}</td>
                    <td style="cursor: pointer!important;text-decoration: underline;"><a (click)="getFolioDetail(item.adisyonno)">{{item.adisyonno}}</a></td>         
                    <td>{{item.NEDEN}}</td>         
                    <td>{{item.IKRAM_ZAMANI | date :  "dd.MM.yyyy h:mm"}}</td> 
                    <td>{{item.MASA_ACAN}}</td>   
                    <td>{{item.garson}}</td>      
                    <td>{{item.urunadi}}</td>  
                    <td>{{item.miktari}}</td>      
                    <td>{{item.birim}}</td>         
                    <td style="text-align: center;">{{item.IKRAM_TOPLAM | currency}}</td>
                    <td style="text-align: center;">{{item.TOPLAM | currency}}</td>
                    <!-- <td style="text-align: center;">{{item.MALIYET | currency}}</td>
                    <td style="text-align: center;">{{item.yekun | currency}}</td> -->
                    <td>{{item.ACIKLAMA}}</td>                     
                </tr>
            </tbody>
        </table>
    </div>
</div> 
<!-- ACIKLAMA: "MİSAFİR İSTEGI"
IKRAM: true
IKRAM_TOPLAM: 6.5
IKRAM_ZAMANI: "2020-11-07T18:06:20.987"
KASIYER: "MERT SOLA"
K_ZAMAN: "2020-11-07T19:11:26.83"
MALIYET: 0.6069
MASA_ACAN: "MERT SOLA"
NEDEN: "DİĞER"
SUBE_ADI: "IZMIR ALSANCAK"
SUBE_KODU: 102
TARIH1: "07.11.2020"
TOPLAM: 6.5
Tarih: "2020-11-07T18:06:00"
adisyonno: "44142B102-00053"
birim: "AD"
garson: "GURKAN DOLUNAY"
garson1: "GURKAN DOLUNAY"
masano: "210.."
miktari: 1
silinme: 0
urunadi: "KK.ZYTN.TAPENADE"
yekun: 330 -->
