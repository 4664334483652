import { Component, Input, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  mailAddress: string = ""
  @Input() myCallback: Function;
  constructor(public glb: GlobalService, public us: UserService) { }


  send() {
    if (this.mailAddress == "") {
      this.glb.pushToast('EPosta Adresi Boş Geçilemez', "warning", 2000)
      return
    }
    this.glb.loadingText = "Şifre Sıfırlama Maili Adresinize Gönderiliyor."
    this.glb.loadingVisible = true;
    this.us.resetPassword(this.mailAddress).then(o => {
     setTimeout(() => {
      this.glb.loadingVisible = false;
      this.myCallback()
      this.mailAddress = ""
     }, 2000);
      if (o.message == "success") {      
        this.glb.pushToast("Şifre Sıfırma Maili Adresinize Gönderildi", "success", 2000)       
      }
     
    })
  }

  ngOnInit(): void {
  }

}
