import { Route } from "@angular/compiler/src/core";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ExcelService } from "src/app/services/excel.service";
import { GlobalService } from "src/app/services/global.service";
import { ReportService } from "src/app/services/report.service";
import { UserService } from "src/app/services/user.service";

import { jsPDF } from "jspdf";

import html2canvas from "html2canvas";

@Component({
  selector: "app-operation-report",
  templateUrl: "./operation-report.component.html",
  styleUrls: ["./operation-report.component.scss"],
})
export class OperationReportComponent implements OnInit {
  constructor(
    public rs: ReportService,
    public us: UserService,
    public glb: GlobalService,
    private nav: Router,
    private exl: ExcelService
  ) {}

  exportExcel() {
    this.glb.loadingText = "Excel'e Aktarılıyor...";
    this.glb.loadingVisible = true;
    setTimeout(() => {
      this.exl.exportAsExcelFile(
        this.rs.operationReport,
        "Günlük Operasyon Raporu"
      );
      this.glb.loadingVisible = false;
      this.glb.pushToast("Excel Aktarımı Tamamlandı.", "success", 2000);
    }, 2000);
  }

  exportToPDF() {
    this.glb.loadingText = "PDF Aktarılıyor...";
    this.glb.loadingVisible = true;
    var data = document.getElementById("contentToConvert");
    html2canvas(data).then((canvas) => {
      var imgData = canvas.toDataURL("image/png");
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;

      var doc = new jsPDF("p", "mm");
      var position = 0;

      doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      doc.save("Günlük Operasyon Raporu.pdf");
      this.glb.loadingVisible = false;
    });
  }

  paylessReport(branchCode) {
    this.glb.loadingText = "Rapor Yükleniyor...";
    this.glb.loadingVisible = true;
      this.changeBranchCode(branchCode);
    this.rs.getPaylessReport().then((o) => {
      this.glb.loadingVisible = false;
      this.nav.navigateByUrl("layout/operation-report/payless-report");
    });
  }

  cancelledFolioReport(branchCode){
    this.glb.loadingText = "Rapor Yükleniyor...";
    this.glb.loadingVisible = true;
      this.changeBranchCode(branchCode);
    this.rs.getCancelledFolioRaport().then((o) => {
      this.glb.loadingVisible = false;
      this.nav.navigateByUrl("layout/operation-report/cancelled-folio-report");
    });
  }

  getGiftReportQty(branchCode) {
      this.changeBranchCode(branchCode);
    this.rs.getGiftReport().then((o) => {
      debugger;
      return this.rs.giftReportData.length;
    });
  }

  getDiscountReport(branchCode) {
    {
      this.glb.loadingText = "Rapor Yükleniyor...";
      this.glb.loadingVisible = true;
        this.changeBranchCode(branchCode);
      this.rs.getDiscountReport().then((o) => {
        this.glb.loadingVisible = false;
        this.nav.navigateByUrl("layout/operation-report/discount-report");
      });
    }
  }

  deletedItemReport(reason, branchCode) {
    {
      this.glb.loadingText = "Rapor Yükleniyor...";
      this.glb.loadingVisible = true;
        this.changeBranchCode(branchCode);
      this.glb.mainRequestData.reason = reason;
      this.rs.getDeletedItemReport().then((o) => {
        this.glb.loadingVisible = false;
        this.nav.navigateByUrl("layout/operation-report/deleted-item-report");
      });
    }
  }

  tableTransactionReport(branchCode) {
    {
      this.glb.loadingText = "Rapor Yükleniyor...";
      this.glb.loadingVisible = true;
        this.changeBranchCode(branchCode);
      this.rs.getTableTransactionReport().then((o) => {
        this.glb.loadingVisible = false;
        this.nav.navigateByUrl(
          "layout/operation-report/table-transaction-report"
        );
      });
    }
  }

  giftReport(branchCode) {
    {
      debugger;
      this.glb.loadingText = "Rapor Yükleniyor...";
      this.glb.loadingVisible = true;
      this.changeBranchCode(branchCode);
      this.rs.getGiftReport().then((o) => {
        this.glb.loadingVisible = false;
        this.nav.navigateByUrl("layout/operation-report/gift-report");
      });
    }
  }
  changeBranchCode(branchCode: any) {
    this.glb.mainRequestData.branchCode.splice(
      0,
      this.glb.mainRequestData.branchCode.length
    );
    this.glb.mainRequestData.branchCode.push(branchCode);
  }

  menuSalesReport(branchCode) {
    {
      this.glb.loadingText = "Rapor Yükleniyor...";
      this.glb.loadingVisible = true;
        this.changeBranchCode(branchCode);
      this.glb.mainRequestData.parameter = "groupType";
      this.rs.getMenuSalesReport().then((o) => {
        this.glb.loadingVisible = false;
        this.nav.navigateByUrl("layout/operation-report/sales-report");
      });
    }
  }

  productFolioReport(branchCode) {
    this.glb.loadingText = "Rapor Yükleniyor...";
    this.glb.loadingVisible = true;
      this.changeBranchCode(branchCode);
    this.glb.mainRequestData.selecetedProduct = "Garsoniye";
    this.glb.mainRequestData.parameter = "folioList";
    this.rs.getFolioByProductSales().then((o) => {
      this.glb.loadingVisible = false;
      this.nav.navigateByUrl(
        "layout/operation-report/product-folio-sales-report"
      );
    });
  }

  ngOnInit(): void {}
}
