import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ExcelService } from 'src/app/services/excel.service';
import { GlobalService } from 'src/app/services/global.service';
import { ReportService } from 'src/app/services/report.service';

import { jsPDF } from "jspdf";
  
import html2canvas from 'html2canvas';


@Component({
  selector: 'app-discount-detail-report',
  templateUrl: './discount-detail-report.component.html',
  styleUrls: ['./discount-detail-report.component.scss']
})
export class DiscountDetailReportComponent implements OnInit {

  constructor(public rs :ReportService,public glb :GlobalService,private nav :Router,private exl : ExcelService) { 
   
  }


  exportExcel()
  {
    this.glb.loadingText = "Excel'e Aktarılıyor..."
    this.glb.loadingVisible = true;
    setTimeout(() => {
      this.exl.exportAsExcelFile(this.rs.discountReportDetail,"İndirim Detay Raporu")
      this.glb.loadingVisible = false;
      this.glb.pushToast('Excel Aktarımı Tamamlandı.','success',2000)
    }, 2000);
  }


  exportToPDF()
{
  this.glb.loadingText = "PDF Aktarılıyor..."
  this.glb.loadingVisible = true;
  var data = document.getElementById('contentToConvert');  
   html2canvas(data).then(canvas => {  
    var imgData = canvas.toDataURL('image/png');
    var imgWidth = 210;
    var pageHeight = 295;
    var imgHeight = canvas.height * imgWidth / canvas.width;
    var heightLeft = imgHeight;
    
    var doc = new jsPDF('p', 'mm');
    var position = 0;
    
    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;
    
    while (heightLeft >= 0) {
    position = heightLeft - imgHeight;
    doc.addPage();
    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;
    }
    doc.save('İndirim Detay Raporu.pdf');
    this.glb.loadingVisible = false;  
  });
}


  getFolioDetail(folioNo)
  {    
    this.glb.loadingVisible = true;
    this.rs.getFolioDetail(folioNo).then(o => {
      this.glb.loadingVisible = false;
      this.nav.navigateByUrl('/layout/operation-report/discount-report/discount-detail-report/folio-detail')
    })
  }

  ngOnInit(): void {
  }

}
