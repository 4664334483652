<div class="req-form" [ngClass]="{ collapsed: formCollapsed }">
  <dx-form id="myForm" #form [formData]="glb.mainRequestData" labelLocation="top">
    <dxi-item #startDate dataField="startedDate" editorType="dxDateBox" [editorOptions]="{
        value: today,
        width: '100%',
        displayFormat: 'dd.MM.yyyy'
      }">
      <dxo-label [visible]="true" style="color: #4c52bc" text="Başlangıç Tarihi"></dxo-label>
      <dxi-validation-rule type="required" message="Lütfen Başlangıç Tarihi Giriniz..."></dxi-validation-rule>
    </dxi-item>
    <dxi-item dataField="endDate" editorType="dxDateBox" #endDate [editorOptions]="{
        value: today,
        width: '100%',
        displayFormat: 'dd.MM.yyyy'
      }">
      <dxo-label style="color: #4c52bc" [visible]="true" text="Bitiş Tarihi"></dxo-label>
      <dxi-validation-rule type="required" message="Lütfen Başlangıç Tarihi Giriniz..."></dxi-validation-rule>
    </dxi-item>

    <dxi-item [visible]="!isBranchHasMultiple && this.glb.activeReport !== 'branchAnalysisReport'"
      dataField="branchCode" editorType="dxTagBox" [editorOptions]="{
        dataSource: rs.branchList,
        valueExpr: 'SUBE_KODU',
        displayExpr: 'SUBE_ADI',
        searchEnabled: true,
        showClearButton: true,
        maxDisplayedTags: 10,
        showSelectionControls: true,
        applyValueMode: 'useButtons',
        placeholder: 'Şube Seçimi...'
      }">
      <dxi-validation-rule type="required" message="Lütfen Şube Seçimi Yapınız."></dxi-validation-rule>
      <dxo-label style="color: #4c52bc" text="Şube Seçiniz"></dxo-label>
    </dxi-item>

    <dxi-item [visible]="isBranchHasMultiple && this.glb.activeReport !== 'branchAnalysisReport'" dataField="branchCode"
      editorType="dxTagBox" [editorOptions]="{
        dataSource: rs.branchList,
        valueExpr: 'SUBE_KODU',
        displayExpr: 'SUBE_ADI',
        showSelectionControls: true,
        searchEnabled: true,
        showClearButton: true,
        maxDisplayedTags: 10,
        placeholder: 'Şube Seçimi...',
        applyValueMode: 'useButtons',
        onValueChanged: getWareHouse
      }">
      <dxi-validation-rule type="required" message="Lütfen Şube Seçimi Yapınız."></dxi-validation-rule>
      <dxo-label style="color: #4c52bc" text="Şube Seçiniz"></dxo-label>
    </dxi-item>

    <dxi-item #wareHouse [visible]="checkReport('warehouse')" dataField="warehouse" editorType="dxTagBox"
      [editorOptions]="{
        dataSource: rs.wareHouses,
        valueExpr: 'DEPO_KODU',
        displayExpr: 'DEPO_ADI',
        searchEnabled: true,
        showClearButton: true,
        maxDisplayedTags: 10,
        showSelectionControls: true,
        applyValueMode: 'useButtons',
        placeholder: 'Depo Seçimi...'
      }">
      <dxi-validation-rule type="required" message="Lütfen Depo Seçimi Yapınız."></dxi-validation-rule>
      <dxo-label style="color: #4c52bc" text="Depo Seçiniz"></dxo-label>
    </dxi-item>

    <dxi-item dataField="selectedGroup" [visible]="checkReport('selectedGroup')" editorType="dxTagBox" [editorOptions]="{
        dataSource: rs.productGroups,
        valueExpr: 'Kimlik',
        displayExpr: 'Grup',
        searchEnabled: true,
        showClearButton: true,
        maxDisplayedTags: 10,
        showSelectionControls: true,
        applyValueMode: 'useButtons',
        placeholder: 'Ürün Grubu Seçimi...',
        onValueChanged: getProducts
      }">
      <dxi-validation-rule type="required" message="Lütfen Ürün Grubu Seçimi Yapınız."></dxi-validation-rule>
      <dxo-label style="color: #4c52bc" text="Ürün Grubu Seçiniz"></dxo-label>
    </dxi-item>

    <dxi-item #productList dataField="stock_code" [visible]="checkReport('stock_code')" editorType="dxTagBox"
      [editorOptions]="{
        dataSource: rs.filtredProducts,
        valueExpr: 'STOK_KODU',
        displayExpr: 'isim',
        searchEnabled: true,
        showClearButton: true,
        maxDisplayedTags: 10,
        showSelectionControls: true,
        applyValueMode: 'useButtons',
        placeholder: 'Ürün Seçimi...'
      }">
      <dxi-validation-rule type="required" message="Lütfen Ürün Seçimi Yapınız."></dxi-validation-rule>
      <dxo-label style="color: #4c52bc" text="Ürün Seçiniz"></dxo-label>
    </dxi-item>

    <dxi-item dataField="serviceTypes" [visible]="checkReport('serviceTypes')" editorType="dxTagBox" [editorOptions]="{
        dataSource: rs.serviceTypes,
        valueExpr: 'KOD',
        displayExpr: 'SERVIS_TIPI',
        searchEnabled: true,
        showClearButton: true,
        maxDisplayedTags: 10,
        showSelectionControls: true,
        applyValueMode: 'useButtons',
        placeholder: 'Servis Tipi Seçimi...'
      }">
      <dxi-validation-rule type="required" message="Lütfen Servis Tipi Seçimi Yapınız."></dxi-validation-rule>
      <dxo-label style="color: #4c52bc" text="Servis Tipi Seçiniz"></dxo-label>
    </dxi-item>

    <dxi-item dataField="useType" [visible]="checkReport('useType')" editorType="dxTagBox" [editorOptions]="{
        dataSource: useTypes,
        valueExpr: 'value',
        displayExpr: 'name',
        searchEnabled: true,
        showClearButton: true,
        maxDisplayedTags: 1,
        showSelectionControls: true,
        applyValueMode: 'useButtons',
        placeholder: 'Kullanım Tipi Seçimi...'
      }">
      <dxi-validation-rule type="required" message="Lütfen Kullanım Tipi Seçimi Yapınız."></dxi-validation-rule>
      <dxo-label style="color: #4c52bc" text="Kullanım Tipi Seçiniz"></dxo-label>
    </dxi-item>

    <dxi-item dataField="reportType" [visible]="checkReport('reportType')" editorType="dxSelectBox" [editorOptions]="{
      dataSource: reportTypes,
      valueExpr: 'id',
      displayExpr: 'name',
      searchEnabled: true,
      showClearButton: true,
      placeholder: 'Rapor Tipi Seçimi...'
    }">
      <dxi-validation-rule type="required" message="Lütfen Rapor Tipi Seçimi Yapınız."></dxi-validation-rule>
      <dxo-label style="color: #4c52bc" text="Rapor Tipi Seçimi"></dxo-label>
    </dxi-item>

    <dxi-item dataField="reportLevel" [visible]="checkReport('reportLevel')" editorType="dxSelectBox" [editorOptions]="{
      dataSource: reportLevelTypes,
      valueExpr: 'id',
      displayExpr: 'name',
      searchEnabled: true,
      showClearButton: true,
      placeholder: 'Seviye Seçimi...'
    }">
      <dxi-validation-rule type="required" message="Lütfen Seviye Seçimi Yapınız."></dxi-validation-rule>
      <dxo-label style="color: #4c52bc" text="Seviye Seçimi"></dxo-label>
    </dxi-item>

    <!-- <dxi-item dataField="showDetail" [visible]="checkReport('showDetail')" editorType="dxSelectBox" [editorOptions]="{
    dataSource: goruntulenmeTypes,
    valueExpr: 'id',
    displayExpr: 'name',
    searchEnabled: true,
    showClearButton: true,
    placeholder: 'Görüntülenme Tipi Seçimi...'
  }">
      <dxi-validation-rule type="required" message="Lütfen Görüntülenme Tipi Seçimi Yapınız."></dxi-validation-rule>
      <dxo-label style="color: #4c52bc" text="Görüntülenme Tipi Seçimi"></dxo-label>
    </dxi-item> -->

    <dxi-item dataField="rawMaterial" [visible]="checkReport('rawMaterial')" editorType="dxSelectBox" [editorOptions]="{
    dataSource: hammaddeTypes,
    valueExpr: 'id',
    displayExpr: 'name',
    searchEnabled: true,
    showClearButton: true,
    placeholder: 'Hammadde Detaylarını Göster'
  }">
      <dxi-validation-rule type="required" message="Lütfen Hammadde Seçimi Yapınız."></dxi-validation-rule>
      <dxo-label style="color: #4c52bc" text="Hammadde Detaylarını Göster"></dxo-label>
    </dxi-item>


    <!-- <dxi-item dataField="reasonType" [visible]="checkReport('reasonType')" editorType="dxSelectBox" [editorOptions]="{
      dataSource: rs.reasonTypes,
      valueExpr: 'KOD',
      displayExpr: 'ACIKLAMA',
      searchEnabled: true,
      showClearButton: true,
      placeholder: 'Sebep Seçimi...'
    }">
      <dxi-validation-rule type="required" message="Lütfen Sebep Seçimi Yapınız."></dxi-validation-rule>
      <dxo-label style="color: #4c52bc" text="Sebep"></dxo-label>
    </dxi-item> -->


    <dxi-item [visible]="checkReport('reasonType')" dataField="reasonType" editorType="dxTagBox" [editorOptions]="{
       dataSource: rs.reasonTypes,
        valueExpr: 'KOD',
        displayExpr: 'ACIKLAMA',
        placeholder: 'Sebep Seçimi...',
        showSelectionControls: true,
        searchEnabled: true,
        showClearButton: true,
        maxDisplayedTags: 10,
        applyValueMode: 'useButtons'
      }">
      <dxi-validation-rule type="required" message="Lütfen Sebep Seçimi Yapınız."></dxi-validation-rule>
      <dxo-label style="color: #4c52bc" text="Sebep"></dxo-label>
    </dxi-item>


    <dxi-item dataField="consolidated" [visible]="checkReport('consolidated')" editorType="dxSelectBox" [editorOptions]="{
        dataSource: consolidateTypes,
        valueExpr: 'id',
        displayExpr: 'name',
        searchEnabled: true,
        showClearButton: true,
        placeholder: 'Konsolide Seçimi...'
      }">
      <dxi-validation-rule type="required" message="Lütfen Konsolide Seçimi Yapınız."></dxi-validation-rule>
      <dxo-label style="color: #4c52bc" text="Konsolide Seçimi"></dxo-label>
    </dxi-item>

    <dxi-item dataField="isConsolidated" [visible]="checkReport('isConsolidated')" editorType="dxSelectBox"
      [editorOptions]="{
        dataSource: konsolideTypesInventory,
        valueExpr: 'id',
        displayExpr: 'name',
        searchEnabled: true,
        showClearButton: true,
        placeholder: 'Konsolide Seçimi...'
      }">
      <dxi-validation-rule type="required" message="Lütfen Konsolide Seçimi Yapınız."></dxi-validation-rule>
      <dxo-label style="color: #4c52bc" text="Konsolide Seçimi"></dxo-label>
    </dxi-item>

    <dxi-item dataField="counting" [visible]="checkReport('counting')" editorType="dxSelectBox" [editorOptions]="{
        dataSource: countingTypes,
        valueExpr: 'id',
        displayExpr: 'name',
        searchEnabled: true,
        showClearButton: true,
        placeholder: 'Sayım Seçimi...'
      }">
      <dxi-validation-rule type="required" message="Lütfen Sayım Seçimi Yapınız."></dxi-validation-rule>
      <dxo-label style="color: #4c52bc" text="Sayım Seçimi"></dxo-label>
    </dxi-item>

    <dxi-item dataField="param" [visible]="checkReport('param')" editorType="dxSelectBox" [editorOptions]="{
        dataSource: parameterTypes,
        valueExpr: 'id',
        displayExpr: 'name',
        searchEnabled: true,
        showClearButton: true,
        placeholder: 'Parametre Seçimi...'
      }">
      <dxi-validation-rule type="required" message="Lütfen Parametre Seçimi Yapınız."></dxi-validation-rule>
      <dxo-label style="color: #4c52bc" text="">Parametre Seçimi</dxo-label>
    </dxi-item>



    <dxi-item dataField="invoiceType" [visible]="checkReport('invoiceType')" editorType="dxTagBox" [editorOptions]="{
        dataSource: invoiceTypes,
        valueExpr: 'value',
        displayExpr: 'name',
        searchEnabled: true,
        showClearButton: true,
        maxDisplayedTags: 2,
        showSelectionControls: true,
        applyValueMode: 'useButtons',
        placeholder: 'Fatura Tipi...'
      }">
      <dxi-validation-rule type="required" message="Lütfen Fatura Tipi Seçimi Yapınız."></dxi-validation-rule>
      <dxo-label style="color: #4c52bc" text="Fatura Tipi Seçiniz"></dxo-label>
    </dxi-item>

    <dxi-item dataField="recortType" [visible]="checkReport('recortType')" editorType="dxTagBox" [editorOptions]="{
        dataSource: recordTypes,
        valueExpr: 'value',
        displayExpr: 'name',
        searchEnabled: true,
        showClearButton: true,
        maxDisplayedTags: 2,
        showSelectionControls: true,
        applyValueMode: 'useButtons',
        placeholder: 'Kayıt Tipi...'
      }">
      <dxi-validation-rule type="required" message="Lütfen Kayıt Tipi Yapınız."></dxi-validation-rule>
      <dxo-label style="color: #4c52bc" text="Kayıt Tipi Seçiniz"></dxo-label>
    </dxi-item>


    <dxi-item dataField="accountCode" [visible]="checkReport('accountCode')" editorType="dxTagBox" [editorOptions]="{
        dataSource: rs.accounts,
        valueExpr: 'CARI_KODU',
        displayExpr: 'CARI_ADI',
        searchEnabled: true,
        showClearButton: true,
        maxDisplayedTags: 2,
        showSelectionControls: true,
        applyValueMode: 'useButtons',
        placeholder: 'Cari Kodu...'
      }">
      <dxi-validation-rule type="required" message="Lütfen Cari Kodu Yapınız."></dxi-validation-rule>
      <dxo-label style="color: #4c52bc" text="Cari Kodu Seçiniz"></dxo-label>
    </dxi-item>



    <dxi-item dataField="isSummary" [visible]="checkReport('isSummary')" editorType="dxSelectBox" [editorOptions]="{
        dataSource: costSummaryParam,
        valueExpr: glb.selectedRepoort == 'InventoryReport' ? 'value' : 'name',
        displayExpr: 'name',
        searchEnabled: true,
        showClearButton: true,
        placeholder: 'Parametre Seçimi...'
      }">
      <dxi-validation-rule type="required" message="Lütfen Parametre Seçimi Yapınız."></dxi-validation-rule>
      <dxo-label style="color: #4c52bc" text="Detay/Özet"></dxo-label>
    </dxi-item>

    <dxi-item dataField="isServiceFee" [visible]="checkReport('isServiceFee')" editorType="dxSelectBox" [editorOptions]="{
        dataSource: onOffData,
        valueExpr: 'value',
        displayExpr: 'name',
        searchEnabled: true,
        showClearButton: true,
        placeholder: 'Parametre Seçimi...'
      }">
      <dxi-validation-rule type="required" message="Lütfen Garsoniye Seçimi Yapınız."></dxi-validation-rule>
      <dxo-label style="color: #4c52bc" text="Garsoniye"></dxo-label>
    </dxi-item>

    <dxi-item dataField="includeVariation" [visible]="checkReport('includeVariation')" editorType="dxSelectBox"
      [editorOptions]="{
        dataSource: onOffData,
        valueExpr: 'value',
        displayExpr: 'name',
        searchEnabled: true,
        showClearButton: true,
        placeholder: 'Parametre Seçimi...'
      }">
      <dxi-validation-rule type="required" message="Lütfen Varyasyon Seçimi Yapınız."></dxi-validation-rule>
      <dxo-label style="color: #4c52bc" text="Varyasyon"></dxo-label>
    </dxi-item>

    <dxi-item dataField="includeTax" [visible]="checkReport('includeTax')" editorType="dxSelectBox" [editorOptions]="{
        dataSource: includeTaxTypes,
        valueExpr: 'id',
        displayExpr: 'name',
        searchEnabled: true,
        showClearButton: true,
        placeholder: 'KDV Seçimi...'
      }">
      <dxi-validation-rule type="required" message="Lütfen KDV Seçimi Yapınız."></dxi-validation-rule>
      <dxo-label style="color: #4c52bc" text="KDV"></dxo-label>
    </dxi-item>

    <dxi-item dataField="vatInclude" [visible]="checkReport('vatInclude')" editorType="dxSelectBox" [editorOptions]="{
        dataSource: onOffData,
        valueExpr: 'value',
        displayExpr: 'name',
        searchEnabled: true,
        showClearButton: true,
        placeholder: 'KDV Seçimi...'
      }">
      <dxi-validation-rule type="required" message="Lütfen KDV Seçimi Yapınız."></dxi-validation-rule>
      <dxo-label style="color: #4c52bc" text="KDV"></dxo-label>
    </dxi-item>

    <dxi-item itemType="empty"></dxi-item>
    <dxi-item itemType="empty"></dxi-item>
    <dxi-item itemType="empty"></dxi-item>
    <!-- <dxi-item>
        <div>
          <dx-button text="Kaydet" type="default" (onClick)='getReportData()' width="100%" style="height: 45px; color:#4c52bc;background-color:transparent;border-style: solid;border-width: 1px;border-color: #4c52bc;border-radius: 30px;">
          </dx-button>
        </div>
      </dxi-item> -->
  </dx-form>
  <div>
    <dx-button type="default" (onClick)="getReportData(form)" width="100%"
      style="height: 45px; background-color: #4c52bc; border-radius: 30px">Rapor Getir
    </dx-button>
  </div>
</div>

<dx-load-panel #loadPanel [message]="glb.loadingText" shadingColor="rgba(0,0,0,0.4)" [indicatorSrc]="glb.indicatorUrl"
  [position]="{ of: '#employee' }" [(visible)]="glb.loadingVisible" [showIndicator]="true" [showPane]="true"
  [shading]="true" [closeOnOutsideClick]="false">
</dx-load-panel>