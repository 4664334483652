<div class="row" style="margin-bottom: 20px;">
    <div class="col-12">
      <button (click)="exportExcel()" style="float: right;" type="button" class="btn btn-success">
        <span class="material-icons">
          get_app
          </span>
      </button>
      <button (click)="exportToPDF()" style="float: right; margin-right: 5px;" type="button" class="btn btn-primary">
        <span class="material-icons">
          picture_as_pdf
          </span>
      </button>
  
    </div>
  </div>

<div class="container shadow" style="margin-bottom: 30px;">
    <div class="col-12">
        <table class="table table-hover" style="margin-bottom: 20px;" id="contentToConvert">
            <thead>
                <tr>                
                    <th style="color:#4C52BC" scope="col">Sube</th>
                    <th style="color:#4C52BC" scope="col">Çek No</th>
                    <th style="color:#4C52BC" scope="col">Açılış Z.</th>
                    <th style="color:#4C52BC" scope="col">Garson</th>
                    <th style="color:#4C52BC" scope="col">Masa No</th>
                    <th style="color:#4C52BC" scope="col">Silen</th>
                    <th style="color:#4C52BC" scope="col">Silme Nedeni</th>
                    <th style="color:#4C52BC" scope="col">Silme Z.</th>
                    <th style="color:#4C52BC" scope="col">Açıklama</th>
                    <th style="color:#4C52BC" scope="col">Servis Tipi</th>
                    <th style="color:#4C52BC" scope="col">Masa Açan</th>
                    <th style="color:#4C52BC" scope="col">Toplam</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of this.rs.cancelledFolioData;let c=index">
                  
                    <td>{{item.SUBE_ADI}}</td>
                    <td style="cursor: pointer!important;text-decoration: underline;"><a (click)="getFolioDetail(item.adisyonno)">{{item.adisyonno}}</a></td>
                    <td>{{item.A_ZAMAN | date :  "dd.MM.yyyy h:mm"}}</td>
                    <td>{{item.garson}}</td>
                    <td>{{item.masano}}</td>
                    <td>{{item.silen}}</td>
                    <td>{{item.SILME_NEDENI}}</td>
                    <td>{{item.SILME_ZAMANI | date :  "dd.MM.yyyy h:mm"}}</td>
                    <td>{{item.SILME_ACIKLAMA}}</td>  
                    <td>{{item.SERVIS_TIPI}}</td>  
                    <td>{{item.MASA_ACAN}}</td>  
                    <td style="text-align: center;">{{item.yekun | currency}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div> 
