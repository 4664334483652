<div class="row" style="margin-bottom: 50px;">
    <div class="col-lg-12 col-md-12 col-xl-12 col-sm-12 col-xs-12 d-flex justify-content-center">
        <span style="color:#475F7B;">Şifrenizi Sıfırlamak İçin Lütfen Kayıtlı E-Posta Adresinizi Giriniz</span>
    </div>
</div>

<div class="row">
    <div class="col-lg-12 col-md-12 col-xl-12 col-sm-12 col-xs-12 d-flex justify-content-center" style="padding-top: 20px;padding-bottom: 20px;">
        <div class="d-flex justify-content-center">
            <dx-validation-group>
                <div class="dx-field ">                                      
                        <dx-text-box placeholder="Mail Adresi" [(value)]="mailAddress" width="350px"
                            [showClearButton]="true" ></dx-text-box>       
                </div>                    
                <div class="dx-field d-flex justify-content-center">
                    <dx-button type="default"
                        style="border-radius: 30px; font-size: 16px; background-color: #4C52BC;color:#ffffff;"
                        text="Gönder" width="350px" (onClick)="send()"></dx-button>
                </div>              
            </dx-validation-group>
        </div>
    </div>
</div>


<dx-load-panel #loadPanel [message]="this.glb.loadingText" shadingColor="rgba(0,0,0,0.4)"
  [indicatorSrc]="this.glb.indicatorUrl" [position]="{ of: '#employee' }" [(visible)]="this.glb.loadingVisible"
  [showIndicator]="true" [showPane]="true" [shading]="true" [closeOnOutsideClick]="false">
</dx-load-panel>