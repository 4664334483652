import { Component, OnInit } from '@angular/core';
import { ExcelService } from 'src/app/services/excel.service';
import { GlobalService } from 'src/app/services/global.service';
import { ReportService } from 'src/app/services/report.service';
import { jsPDF } from "jspdf";
  
import html2canvas from 'html2canvas';
import { Router } from '@angular/router';

@Component({
  selector: 'app-canceled-folio-report',
  templateUrl: './canceled-folio-report.component.html',
  styleUrls: ['./canceled-folio-report.component.scss']
})
export class CanceledFolioReportComponent implements OnInit {

  constructor(public rs: ReportService,public glb : GlobalService,private nav: Router,private exl : ExcelService) { }

  ngOnInit(): void {
  }

  exportExcel()
  {
    this.glb.loadingText = "Excel'e Aktarılıyor..."
    this.glb.loadingVisible = true;
    setTimeout(() => {
      this.exl.exportAsExcelFile(this.rs.cancelledFolioData,"İptal Adisyon Raporu")
      this.glb.loadingVisible = false;
      this.glb.pushToast('Excel Aktarımı Tamamlandı.','success',2000)
    }, 2000);
  }

  exportToPDF()
{
  this.glb.loadingText = "PDF Aktarılıyor..."
  this.glb.loadingVisible = true;
  var data = document.getElementById('contentToConvert');  
   html2canvas(data).then(canvas => {  
    var imgData = canvas.toDataURL('image/png');
    var imgWidth = 210;
    var pageHeight = 295;
    var imgHeight = canvas.height * imgWidth / canvas.width;
    var heightLeft = imgHeight;
    
    var doc = new jsPDF('p', 'mm');
    var position = 0;
    
    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;
    
    while (heightLeft >= 0) {
    position = heightLeft - imgHeight;
    doc.addPage();
    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;
    }
    doc.save('İptal Adisyon Raporu.pdf');
    this.glb.loadingVisible = false;  
  });
}

  getFolioDetail(folioNo)
  {    
    
    this.glb.loadingVisible = true;
    this.rs.getFolioDetail(folioNo).then(o => {
      this.glb.loadingVisible = false;
      this.nav.navigateByUrl('layout/operation-report/cancelled-folio-report/folio-detail')
    })
  }

}
