<div class="row" style="margin-bottom: 20px;">
    <div class="col-12">     
      <button (click)="exportToPDF()" style="float: right; margin-right: 5px;" type="button" class="btn btn-primary">
        <span class="material-icons">
          picture_as_pdf
          </span>
      </button>
  
    </div>
  </div>

<div class="container shadow" style="margin-bottom: 30px;">
    <div class="col-12" id="contentToConvert">
        <dx-data-grid id="gridContainer" [dataSource]="this.rs.salesReportByServiceType" [allowColumnResizing]= "true" [showBorders]="true">
            <dxo-grouping [contextMenuEnabled]="true">
            </dxo-grouping>
            <dxo-export [enabled]="true"></dxo-export>
            <dxo-group-panel [visible]="true">
                <!-- or "auto" -->
            </dxo-group-panel>
            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-editing mode="row" [allowAdding]="false" [allowUpdating]="false" [allowDeleting]="false"
                [useIcons]="true">
            </dxo-editing>
            <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
            <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 20]" [showInfo]="true">
            </dxo-pager>
            <dxo-summary>
                <dxi-group-item
                column="TOPLAM_GELIR" summaryType="sum" valueFormat="₺ #,###.00" displayFormat="{0}">
            </dxi-group-item>
            <dxi-total-item column="TOPLAM_GELIR" summaryType="sum" valueFormat="₺ #,###.00" displayFormat="{0}">
            </dxi-total-item>
            </dxo-summary>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]" [showInfo]="true">
            </dxo-pager>
            <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
           
            <dxi-column dataField="SUBE_ADI"   caption="Şube Adı"> </dxi-column>          
            <dxi-column dataField="STOK_KODU"  [visible]="checkVisible('STOK_KODU')" dataType="string" caption="Stok Kodu"></dxi-column> 
            <dxi-column dataField="isim" [visible]="checkVisible('isim')" dataType="string" caption="Ürün Adı"></dxi-column>                       
            <dxi-column dataField="MIKTAR" [visible]="checkVisible('MIKTAR')" dataType="number" caption="Miktar"></dxi-column>  
            <dxi-column dataField="GRUP" [visible]="checkVisible('GRUP')" dataType="string" caption="Grup"></dxi-column>
            <dxi-column dataField="ANA_GRUP" [visible]="checkVisible('ANA_GRUP')" dataType="string" caption="Ana Grup"></dxi-column>
            <dxi-column dataField="GRUP_TURU" [visible]="checkVisible('GRUP_TURU')" dataType="string" caption="Grup Türü"></dxi-column>
            <dxi-column dataField="SERVIS_TIPI" dataType="string" caption="Servis Tipi"></dxi-column>
            <dxi-column dataField="NET_KDVSIZ"   format="₺ #,###.00" dataType="number" caption="Net KDV'siz"></dxi-column> 
            <dxi-column dataField="KDVSIZ_INDIRIMSIZ_TOPLAM"  format="₺ #,###.00" dataType="number"  caption="KDV'Siz İndirim Toplam"></dxi-column>   
            <dxi-column dataField="INDIRIM"  format="₺ #,###.00" dataType="number"  caption="İndirim"></dxi-column> 
            <dxi-column dataField="NET_INDIRIM"  format="₺ #,###.00" dataType="number"  caption="Net İndirim"></dxi-column>             
            <dxi-column dataField="BRUT_INDIRIM" [visible]="checkVisible('BRUT_INDIRIM')"  format="₺ #,###.00" dataType="number"  caption="Brüt İndirim"></dxi-column> 
            <dxi-column dataField="BRUT_TOPLAM"  format="₺ #,###.00" dataType="number"  caption="Brüt Toplam"></dxi-column> 
            <dxi-column dataField="TOPLAM_GELIR"  format="₺ #,###.00" dataType="number"  caption="Toplam Gelir"></dxi-column>  
            <!-- <dxi-column dataField="MALIYET_ORANI" dataType="number"  format="##.##%" dataType="number"  caption="Maliyet Oranı(%)"></dxi-column>               -->
        </dx-data-grid>

        
    </div>
</div>