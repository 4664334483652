<div class="row" style="margin-bottom: 20px">
    <div class="col-12">
        <button (click)="exportExcel()" style="float: right" type="button" class="btn btn-success">
            <span class="material-icons"> get_app </span>
        </button>
        <!-- <button
        (click)="exportToPDF()"
        style="float: right; margin-right: 5px"
        type="button"
        class="btn btn-primary"
      >
        <span class="material-icons"> picture_as_pdf </span>
      </button> -->
    </div>
</div>

<div>
    <dx-data-grid [columnAutoWidth]="true" [columnHidingEnabled]="false" [scrolling]="{ showScrollbar: 'always' }"
        [columnHidingEnabled]="true" [dataSource]="rs.vatTurnoverReports" [customizeColumns]="customizeColumns">
        <dxo-column-fixing [enabled]="false"></dxo-column-fixing>

        <dxo-column-chooser [enabled]="true" mode="select"> </dxo-column-chooser>
    </dx-data-grid>
</div>

<div class="container shadow" style="margin-bottom: 30px; display: none">
    <div class="col-12">
        <table class="table table-hover" style="margin-bottom: 20px" id="vatTurnoverReports">
            <thead>
                <tr *ngFor="let item of rs.vatTurnoverReports; let c = index">
                    <th *ngFor="let elm of convertToEntries(item)" style="color: #4c52bc" scope="col">{{elm[0] |
                        titlecase}}</th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let item of rs.vatTurnoverReports; let c = index">
                    <td *ngFor="let elm of convertToEntries(item)">{{ elm[1] }}</td>
                    <!-- <td>{{ item.GRUP }}</td>
            <td>{{ item.STOK_KODU }}</td>
            <td>{{ item.STOK_ADI }}</td>
            <td>{{ item.TEMEL_BIRIMI }}</td>
            <td>{{ item.MIKTAR }}</td>
            <td>{{ item.KDV_HARIC }}</td>
            <td>{{ item.KDV_DAHIL }}</td>
            <td>{{ item.KDV_ORANI }}</td>
            <td>{{ item.KDV_TUTARI | currency }}</td>
            <td>{{ item.ORAN | currency }}</td> -->
                </tr>
            </tbody>
        </table>
    </div>
</div>