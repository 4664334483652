<div class="mx-auto">

    <div class="row" style="margin-bottom: 10px;">
        <div class="col-md-12 col-xs-12 col-sm-12 col-lg-12 col-xl-12 d-flex justify-content-end float-right">
            <dx-text-box placeholder="Arama..." width="100%" valueChangeEvent="keyup"
                (onValueChanged)="searchStore($event)">
            </dx-text-box>
        </div>
    </div>
    <dx-list [items]="this.licanceList" height="100%">
        <div *dxTemplate="let item of 'item'">
            <div class="product">
                <div (click)="changeStore(item.licanceId)">
                    <!-- <div *ngIf="item.storeInfo.isProduction == true">
                        <fa-icon style="color:#28a745;" [icon]="faStoreAlt"></fa-icon> {{item.storeInfo.name}}
                    </div> -->
                  
             <label>{{item.brand}}</label>
                  

                </div>
            </div>
        </div>
    </dx-list>
    <!-- <div class="dx-field d-flex justify-content-center" style="margin-top: 10px;">
        <dx-button type="default" text="{{'Cancel' | translate}}" (onClick)="cancel()" class="form-cancel-btn">
        </dx-button>
    </div> -->
</div>

<dx-load-panel #loadPanel [message]="loadingText" shadingColor="rgba(0,0,0,0.4)" [indicatorSrc]="this.glb.indicatorUrl" [position]="{ of: '#employee' }"
    [(visible)]="loadingVisible" [showIndicator]="true" [showPane]="true" [shading]="true"
    [closeOnOutsideClick]="false">
</dx-load-panel>