import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { ExcelService } from "src/app/services/excel.service";
import { GlobalService } from "src/app/services/global.service";
import { ReportService } from "src/app/services/report.service";
import { UserService } from "src/app/services/user.service";
@Component({
  selector: "app-invoice-report",
  templateUrl: "./invoice-report.component.html",
  styleUrls: ["./invoice-report.component.scss"],
})
export class InvoiceReportComponent implements OnInit {
  constructor(
    public rs: ReportService,
    public us: UserService,
    public glb: GlobalService,
    private nav: Router,
    private exl: ExcelService
  ) {
    console.log("ada", this.rs.paylessReportDetailData);
  }

  exportExcel() {
    this.glb.loadingText = "Excel'e Aktarılıyor...";
    this.glb.loadingVisible = true;
    setTimeout(() => {
      this.exl.exportAsExcelFile(
        this.formatDate(this.rs.invoiceReport),
        "Fatura Raporu"
      );
      this.glb.loadingVisible = false;
      this.glb.pushToast("Excel Aktarımı Tamamlandı.", "success", 2000);
    }, 2000);
  }

  formatDate(array: any[]) {
    let DateFields = ["TARIH", "FIILI_TARIH", "ODEME_TARIHI"];
    return array.map(elm => {
      Object.keys(elm).forEach(key => {
        if(DateFields.includes(key)) {
          let date = new Date(elm[key]);
          elm[key] = `${("0" + date.getDate()).slice(-2)}/${("0" + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()}`
        }
      });

      return elm
    })
  }

  exportToPDF() {
    this.glb.loadingText = "PDF Aktarılıyor...";
    this.glb.loadingVisible = true;
    var data = document.getElementById("contentToConvert");
    html2canvas(data).then((canvas) => {
      var imgData = canvas.toDataURL("image/png");
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;

      var doc = new jsPDF("p", "mm");
      var position = 0;

      doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      doc.save("Fatura Raporu.pdf");
      this.glb.loadingVisible = false;
    });
  }

  customizeColumns(e: any[]) {
    // if (e.rowType === "data" && e.column.dataField === "FARK_MALIYET") {
    //   return this.currencyPipe.transform(e.data.FARK_MALIYET, 'TRY');
    // }
    let DateFields = ["TARIH", "FIILI_TARIH", "ODEME_TARIHI"];
    let fields = e.filter((elm) => DateFields.includes(elm.dataField));
    for (const field of fields) {
      field.format = 'dd/MM/yyyy';
      field.dataType = 'date';
      
    }
  //   {  
  //     dataField: 'dateField',  
  //     dataType: 'date',  
  //     format: 'dd/MM/yyyy'  
  // },
  }
  //   {
  //     "ISLETME_ADI": "FRANCHISE",
  //     "BOLGE_ADI": "VARSAYILAN",
  //     "DEPO_KODU": 105,
  //     "DEPO_ADI": "SEYRANTEPE_DEPO",
  //     "FATURA_TIPI": "ALIŞ FATURASI",
  //     "KAYIT_TIPI": "AÇIK",
  //     "TARIH": "2022-03-30T00:00:00",
  //     "FIILI_TARIH": "2022-03-30T00:00:00",
  //     "ODEME_TARIHI": "2022-03-30T00:00:00",
  //     "CARI_KODU": "320-01-095",
  //     "CARI_ADI": "EMRAH ECEL-ŞİRİN MANTAR DAĞITIM PAZARLAMA",
  //     "FATURA_NO": "454535",
  //     "ANA_GRUP": "HAMMADDE",
  //     "GRUP": "SEBZE GRUBU",
  //     "STOK_KODU": "SEB.0007",
  //     "STOK_ADI": "MANTAR",
  //     "FATURA_MIKTARI": 15,
  //     "FATURA_BIRIMI": "KG",
  //     "ISKONTOSUZ_BIRIM_FIYAT_KDV_HARIC": 22,
  //     "KDV_ORANI": 1,
  //     "ISKONTO_1_ORANI": 0,
  //     "ISKONTO_2_ORANI": 0,
  //     "FATURA_ALTI_ISKONTO_ORANI": 0,
  //     "TEMEL_BIRIM_MIKTARI": 15,
  //     "TEMEL_BIRIMI": "KG",
  //     "TEMEL_BIRIM_MALIYETI_KDV_DAHIL": 22.22,
  //     "TEMEL_BIRIM_MALIYETI_KDV_HARIC": 22,
  //     "HAR_KDVSIZ_TOPLAM": 330,
  //     "HAR_GENEL_TOPLAM": 333.3,
  //     "FB_GENEL_TOPLAM": 333.3,
  //     "FB_TOPLAM_KDV": 3.3,
  //     "FB_ACIKLAMA": "",
  //     "HAR_ACIKLAMA": "",
  //     "HAR_PARTINO": "",
  //     "HAR_SKT": null,
  //     "FB_IRS_TARIHI": null,
  //     "FB_IRS_NO": "",
  //     "HAR_IRS_TARIH": null,
  //     "HAR_IRS_NO": "",
  //     "KDV_TUTARI": 3.3000000000000114
  // }


  ngOnInit(): void {}
}
