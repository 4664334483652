import { Injectable } from "@angular/core";
import { observable } from "rxjs";
import {
  Account,
  Branch,
  InventoryReport,
  InvoiceReport,
  ProductGroup,
  ReasonType,
  ServiceTypes,
  WareHouse,
} from "../models/global-model";
import { RequestData } from "../models/request-form-model";
import { ConnectorService } from "./connector.service";
import { GlobalService } from "./global.service";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class ReportService {
  branchList: Branch[] = [];
  serviceTypes: ServiceTypes[] = [];
  wareHouses: WareHouse[] = [];
  accounts: Account[] = [];
  productGroups: ProductGroup[] = [];
  reasonTypes: ReasonType[] = [];
  operationReport: any;
  discountReport: any;
  discountReportDetail: any;
  folioDetail: any;
  menuSaleReport: any;
  menuSaleReportDetail: any;
  groupSaleDetailReport: any;
  groupSaleReport: any;
  productSaleReport: any;
  folioListByProductSales: any;
  deletedItemData: any;
  tableTransactionData: any;
  giftReportData: any;
  cancelledFolioData: any;
  giftDetailData: any;
  salesCostData: any;
  salesReportByServiceType: any;
  wastageStaffReports: any = [];
  vatTurnoverReports: any = [];
  inventoryReport: any[] = [];
  invoiceReport: InvoiceReport[] = [];
  paylessReportData: any;
  branchAnaylsisReport: any[] = [];
  paylessReportDetailData: any;
  products: any[] = [];
  filtredProducts: any[] = [];

  constructor(
    public glb: GlobalService,
    public cs: ConnectorService,

    public us: UserService
  ) {}

  getBranchList() {
    return new Promise((resolve, reject) => {
      this.cs
        .serviceRequest(
          "/api/branch/getBranchList",
          {
            licanceNo: parseInt(this.us.activeLicance.licanceId),
            companyCode: 0,
          },
          this.us.user.accessToken
        )
        .toPromise()
        .then(
          (o) => {
            // debugger;
            this.branchList = Object.assign(o);
            let returnVal: Branch[] = Object.assign(o);
            if (this.us.activeLicance.branchCodes.length > 0) {
              this.branchList = [];
              for (
                let index = 0;
                index < this.us.activeLicance.branchCodes.length;
                index++
              ) {
                const element: any =
                  this.us.activeLicance.branchCodes[index];
                let branch: Branch = returnVal.find(
                  (x) => x.SUBE_KODU == element
                );
                this.branchList.push(branch);
              }
            }

            resolve(true);
          },
          (e) => {
            console.log("BranchList error", e);
            if (e.message == "Timeout has occurred") {
              this.glb.pushToast("İstek Zamana Aşımına Uğradı", "error", 2000);
            } else {
              this.glb.pushToast(e.message, "error", 2000);
            }
            this.glb.loadingVisible = false;
            reject("error");
          }
        );
    });
  }

  async getProductList(groups: string) {
    await this.cs
      .serviceRequest(
        "/api/branch/getProductList",
        {
          licanceNo: parseInt(this.us.activeLicance.licanceId),
          groups,
        },
        this.us.user.accessToken
      )
      .toPromise()
      .then((o) => {
        this.products = Object.assign(o);
      });
  }

  async getServiceTypes() {
    return this.cs
      .serviceRequest(
        "/api/folio/getServiceTypes",
        {
          licanceNo: parseInt(this.us.activeLicance.licanceId),
          companyCode: 0,
        },
        this.us.user.accessToken
      )
      .toPromise()
      .then((o) => {
        this.serviceTypes = Object.assign(o);
      });
  }

  async getReasonTypes() {
    return this.cs
      .serviceRequest(
        "/api/branch/getWastageTypes",
        {
          licanceNo: parseInt(this.us.activeLicance.licanceId),
        },
        this.us.user.accessToken
      )
      .toPromise()
      .then((o) => {
        this.reasonTypes = Object.assign(o);
      });
  }

  async getAccountList() {
    return this.cs
      .serviceRequest(
        "/api/invoice/getAccountList",
        {
          licanceNo: parseInt(this.us.activeLicance.licanceId),
        },
        this.us.user.accessToken
      )
      .toPromise()
      .then((o) => {
        this.accounts = Object.assign(o);
      });
  }

  async getWareHouses(branchCode: string) {
    debugger;
    await this.cs
      .serviceRequest(
        "/api/branch/getWarehouses",
        {
          licanceNo: parseInt(this.us.activeLicance.licanceId),
          branchCode,
        },
        this.us.user.accessToken
      )
      .toPromise()
      .then((o) => {
        this.wareHouses = Object.assign(o);
      });
  }

  getProductGroups() {
    return new Promise((resolve, reject) => {
      this.cs
        .serviceRequest(
          "/api/branch/getProductGroups",
          { licanceNo: parseInt(this.us.activeLicance.licanceId) },
          this.us.user.accessToken
        )
        .toPromise()
        .then(
          (o) => {
            // console.log(Object.assign(o));
            this.productGroups = Object.assign(o);
            resolve(o);
          },
          (e) => {
            console.log("BranchList error", e);
            if (e.message == "Timeout has occurred") {
              this.glb.pushToast("İstek Zamana Aşımına Uğradı", "error", 2000);
            } else {
              this.glb.pushToast(e.message, "error", 2000);
            }
            this.glb.loadingVisible = false;
            reject("error");
          }
        );
    });
  }

  getOperationReport() {
    return new Promise((resolve, reject) => {
      this.cs
        .serviceRequest(
          "/api/Report/dailyReportSummary",
          {
            requestModel: this.glb.mainRequestData,
            licanceNo: parseInt(this.us.activeLicance.licanceId),
          },
          this.us.user.accessToken
        )
        .toPromise()
        .then(
          (o) => {
            console.log(Object.assign(o));
            this.operationReport = Object.assign(o);
            resolve(Object.assign(o));
          },
          (e) => {
            if (e.message == "Timeout has occurred") {
              this.glb.pushToast("İstek Zamana Aşımına Uğradı", "error", 2000);
            } else {
              this.glb.pushToast(e.message, "error", 2000);
            }
            this.glb.loadingVisible = false;
          }
        );
    });
  }

  getDiscountReport() {
    return new Promise((resolve, reject) => {
      this.cs
        .serviceRequest(
          "/api/folio/getDiscountReport",
          {
            requestModel: this.glb.mainRequestData,
            licanceNo: parseInt(this.us.activeLicance.licanceId),
          },
          this.us.user.accessToken
        )
        .toPromise()
        .then(
          (o) => {
            console.log("discountReport", Object.assign(o));
            this.discountReport = Object.assign(o);
            resolve(Object.assign(o));
          },
          (e) => {
            if (e.message == "Timeout has occurred") {
              this.glb.pushToast("İstek Zamana Aşımına Uğradı", "error", 2000);
            } else {
              this.glb.pushToast(e.message, "error", 2000);
            }
            this.glb.loadingVisible = false;
          }
        );
    });
  }

  getDeletedItemReport() {
    return new Promise((resolve, reject) => {
      // debugger;
      this.cs
        .serviceRequest(
          "/api/Report/DeletedItemsByReason",
          {
            requestModel: this.glb.mainRequestData,
            licanceNo: parseInt(this.us.activeLicance.licanceId),
            reason: this.glb.mainRequestData.reason,
          },
          this.us.user.accessToken
        )
        .toPromise()
        .then(
          (o) => {
            console.log("deletedItemReport", Object.assign(o));
            this.deletedItemData = Object.assign(o);
            resolve(Object.assign(o));
          },
          (e) => {
            if (e.message == "Timeout has occurred") {
              this.glb.pushToast("İstek Zamana Aşımına Uğradı", "error", 2000);
            } else {
              this.glb.pushToast(e.message, "error", 2000);
            }
            this.glb.loadingVisible = false;
          }
        );
    });
  }

  getTableTransactionReport() {
    return new Promise((resolve, reject) => {
      // debugger;
      this.cs
        .serviceRequest(
          "/api/table/tableTransaction",
          {
            requestModel: this.glb.mainRequestData,
            licanceNo: parseInt(this.us.activeLicance.licanceId),
          },
          this.us.user.accessToken
        )
        .toPromise()
        .then(
          (o) => {
            console.log("tableTransaction", Object.assign(o));
            this.tableTransactionData = Object.assign(o);
            resolve(Object.assign(o));
          },
          (e) => {
            console.log("BranchList error", e);
            if (e.message == "Timeout has occurred") {
              this.glb.pushToast("İstek Zamana Aşımına Uğradı", "error", 2000);
            } else {
              this.glb.pushToast(e.message, "error", 2000);
            }
            this.glb.loadingVisible = false;
          }
        );
    });
  }

  getMenuSalesReport() {
    return new Promise((resolve, reject) => {
      this.cs
        .serviceRequest(
          "/api/Report/MenuItemReport",
          {
            requestModel: this.glb.mainRequestData,
            licanceNo: parseInt(this.us.activeLicance.licanceId),
            parameter: this.glb.mainRequestData.parameter,
          },
          this.us.user.accessToken
        )
        .toPromise()
        .then(
          (o) => {
            console.log(Object.assign(o));
            this.menuSaleReport = Object.assign(o);
            resolve(Object.assign(o));
          },
          (e) => {
            console.log("BranchList error", e);
            if (e.message == "Timeout has occurred") {
              this.glb.pushToast("İstek Zamana Aşımına Uğradı", "error", 2000);
            } else {
              this.glb.pushToast(e.message, "error", 2000);
            }
            this.glb.loadingVisible = false;
          }
        );
    });
  }

  getGroupSalesReport() {
    return new Promise((resolve, reject) => {
      this.cs
        .serviceRequest(
          "/api/Report/MenuItemReport",
          {
            requestModel: this.glb.mainRequestData,
            licanceNo: parseInt(this.us.activeLicance.licanceId),
            parameter: this.glb.mainRequestData.selectedGroup,
            parameter1: this.glb.mainRequestData.parameter,
          },
          this.us.user.accessToken
        )
        .toPromise()
        .then(
          (o) => {
            console.log(Object.assign(o));
            this.groupSaleReport = Object.assign(o);
            resolve(Object.assign(o));
          },
          (e) => {
            if (e.message == "Timeout has occurred") {
              this.glb.pushToast("İstek Zamana Aşımına Uğradı", "error", 2000);
            } else {
              this.glb.pushToast(e.message, "error", 2000);
            }
            this.glb.loadingVisible = false;
          }
        );
    });
  }

  getProductSalesReport() {
    return new Promise((resolve, reject) => {
      this.cs
        .serviceRequest(
          "/api/Report/MenuItemReport",
          {
            requestModel: this.glb.mainRequestData,
            licanceNo: parseInt(this.us.activeLicance.licanceId),
            parameter: this.glb.mainRequestData.selectedGroup,
            parameter1: this.glb.mainRequestData.parameter,
          },
          this.us.user.accessToken
        )
        .toPromise()
        .then(
          (o) => {
            console.log(Object.assign(o));
            this.productSaleReport = Object.assign(o);
            resolve(Object.assign(o));
          },
          (e) => {
            if (e.message == "Timeout has occurred") {
              this.glb.pushToast("İstek Zamana Aşımına Uğradı", "error", 2000);
            } else {
              this.glb.pushToast(e.message, "error", 2000);
            }
            this.glb.loadingVisible = false;
          }
        );
    });
  }

  getFolioByProductSales() {
    return new Promise((resolve, reject) => {
      this.cs
        .serviceRequest(
          "/api/Report/MenuItemReport",
          {
            requestModel: this.glb.mainRequestData,
            licanceNo: parseInt(this.us.activeLicance.licanceId),
            parameter: this.glb.mainRequestData.selecetedProduct,
            parameter1: this.glb.mainRequestData.parameter,
          },
          this.us.user.accessToken
        )
        .toPromise()
        .then(
          (o) => {
            console.log(Object.assign(o));
            this.folioListByProductSales = Object.assign(o);
            resolve(Object.assign(o));
          },
          (e) => {
            if (e.message == "Timeout has occurred") {
              this.glb.pushToast("İstek Zamana Aşımına Uğradı", "error", 2000);
            } else {
              this.glb.pushToast(e.message, "error", 2000);
            }
            this.glb.loadingVisible = false;
          }
        );
    });
  }

  getDiscountReportDetail() {
    return new Promise((resolve, reject) => {
      this.cs
        .serviceRequest(
          "/api/folio/getDiscountReportByReason",
          {
            requestModel: this.glb.mainRequestData,
            licanceNo: parseInt(this.us.activeLicance.licanceId),
            reason: this.glb.mainRequestData.reason,
          },
          this.us.user.accessToken
        )
        .toPromise()
        .then(
          (o) => {
            console.log(Object.assign(o));
            this.discountReportDetail = Object.assign(o);
            resolve(Object.assign(o));
          },
          (e) => {
            if (e.message == "Timeout has occurred") {
              this.glb.pushToast("İstek Zamana Aşımına Uğradı", "error", 2000);
            } else {
              this.glb.pushToast(e.message, "error", 2000);
            }
            this.glb.loadingVisible = false;
          }
        );
    });
  }

  getBranchAnalysisReport() {
    return new Promise((resolve, reject) => {
      this.cs
        .serviceRequest(
          "/api/Report/getBranchAnalysisReport",
          {
            requestModel: this.glb.mainRequestData,
            licanceNo: parseInt(this.us.activeLicance.licanceId),
          },
          this.us.user.accessToken
        )
        .toPromise()
        .then(
          (o) => {
            console.log(Object.assign(o));
            this.branchAnaylsisReport = Object.assign(o);
            resolve(Object.assign(o));
          },
          (e) => {
            if (e.message == "Timeout has occurred") {
              this.glb.pushToast("İstek Zamana Aşımına Uğradı", "error", 2000);
            } else {
              this.glb.pushToast(e.message, "error", 2000);
            }
            this.glb.loadingVisible = false;
          }
        );
    });
  }

  getPaylessReport() {
    return new Promise((resolve, reject) => {
      this.cs
        .serviceRequest(
          "/api/folio/getPaylessReport",
          {
            requestModel: this.glb.mainRequestData,
            licanceNo: parseInt(this.us.activeLicance.licanceId),
          },
          this.us.user.accessToken
        )
        .toPromise()
        .then(
          (o) => {
            console.log(Object.assign(o));
            this.paylessReportData = Object.assign(o);
            resolve(Object.assign(o));
          },
          (e) => {
            if (e.message == "Timeout has occurred") {
              this.glb.pushToast("İstek Zamana Aşımına Uğradı", "error", 2000);
            } else {
              this.glb.pushToast(e.message, "error", 2000);
            }
            this.glb.loadingVisible = false;
          }
        );
    });
  }

  getPaylessDetailReport() {
    return new Promise((resolve, reject) => {
      this.cs
        .serviceRequest(
          "/api/folio/getPaylessDetailReport",
          {
            requestModel: this.glb.mainRequestData,
            licanceNo: parseInt(this.us.activeLicance.licanceId),
          },
          this.us.user.accessToken
        )
        .toPromise()
        .then(
          (o) => {
            console.log(Object.assign(o));
            this.paylessReportDetailData = Object.assign(o);
            resolve(Object.assign(o));
          },
          (e) => {
            if (e.message == "Timeout has occurred") {
              this.glb.pushToast("İstek Zamana Aşımına Uğradı", "error", 2000);
            } else {
              this.glb.pushToast(e.message, "error", 2000);
            }
            this.glb.loadingVisible = false;
          }
        );
    });
  }

  getFolioDetail(folioNo) {
    return new Promise((resolve, reject) => {
      this.cs
        .serviceRequest(
          "/api/folio/getFolioDetail",
          {
            folioNo: folioNo,
            licanceNo: parseInt(this.us.activeLicance.licanceId),
          },
          this.us.user.accessToken
        )
        .toPromise()
        .then(
          (o) => {
            console.log(Object.assign(o));
            this.folioDetail = Object.assign(o);
            if (this.folioDetail.length == 0) {
              this.glb.pushToast(
                folioNo + " Numaralı Adisyon Bulunamadı.",
                "warning",
                2000
              );
            }
            this.glb.loadingVisible = false;
            resolve(Object.assign(o));
          },
          (e) => {
            if (e.message == "Timeout has occurred") {
              this.glb.pushToast("İstek Zamana Aşımına Uğradı", "error", 2000);
            } else {
              this.glb.pushToast(e.message, "error", 2000);
            }
            this.glb.loadingVisible = false;
          }
        );
    });
  }

  getCancelledFolioRaport() {
    return new Promise((resolve, reject) => {
      this.cs
        .serviceRequest(
          "/api/folio/getCancelledFolios",
          {
            requestModel: this.glb.mainRequestData,
            licanceNo: parseInt(this.us.activeLicance.licanceId),
          },
          this.us.user.accessToken
        )
        .toPromise()
        .then(
          (o) => {
            console.log("Gift Report", Object.assign(o));
            this.cancelledFolioData = Object.assign(o);
            resolve(Object.assign(o));
          },
          (e) => {
            if (e.message == "Timeout has occurred") {
              this.glb.pushToast("İstek Zamana Aşımına Uğradı", "error", 2000);
            } else {
              this.glb.pushToast(e.message, "error", 2000);
            }
            this.glb.loadingVisible = false;
          }
        );
    });
  }

  getGiftReport() {
    return new Promise((resolve, reject) => {
      this.cs
        .serviceRequest(
          "/api/folio/getProductGiftReport",
          {
            requestModel: this.glb.mainRequestData,
            licanceNo: parseInt(this.us.activeLicance.licanceId),
          },
          this.us.user.accessToken
        )
        .toPromise()
        .then(
          (o) => {
            console.log("Gift Report", Object.assign(o));
            this.giftReportData = Object.assign(o);
            resolve(Object.assign(o));
          },
          (e) => {
            if (e.message == "Timeout has occurred") {
              this.glb.pushToast("İstek Zamana Aşımına Uğradı", "error", 2000);
            } else {
              this.glb.pushToast(e.message, "error", 2000);
            }
            this.glb.loadingVisible = false;
          }
        );
    });
  }

  getGiftDetailReport() {
    return new Promise((resolve, reject) => {
      this.cs
        .serviceRequest(
          "/api/folio/getGiftDetailReport",
          {
            requestModel: this.glb.mainRequestData,
            licanceNo: parseInt(this.us.activeLicance.licanceId),
          },
          this.us.user.accessToken
        )
        .toPromise()
        .then(
          (o) => {
            console.log("Gift Detail Report", Object.assign(o));
            this.giftDetailData = Object.assign(o);
            resolve(Object.assign(o));
          },
          (e) => {
            if (e.message == "Timeout has occurred") {
              this.glb.pushToast("İstek Zamana Aşımına Uğradı", "error", 2000);
            } else {
              this.glb.pushToast(e.message, "error", 2000);
            }
            this.glb.loadingVisible = false;
          }
        );
    });
  }

  getGiftDetailReportWidthParams(param: RequestData) {
    return new Promise((resolve, reject) => {
      this.cs
        .serviceRequest(
          "/api/folio/getGiftDetailReport",
          {
            requestModel: param,
            licanceNo: parseInt(this.us.activeLicance.licanceId),
          },
          this.us.user.accessToken
        )
        .toPromise()
        .then(
          (o) => {
            console.log("Gift Detail Report", Object.assign(o));
            this.giftDetailData = Object.assign(o);
            resolve(Object.assign(o));
          },
          (e) => {
            if (e.message == "Timeout has occurred") {
              this.glb.pushToast("İstek Zamana Aşımına Uğradı", "error", 2000);
            } else {
              this.glb.pushToast(e.message, "error", 2000);
            }
            this.glb.loadingVisible = false;
          }
        );
    });
  }

  getSalesCostReport() {
    let endDate = this.glb.mainRequestData.endDate;
    endDate.setHours(23, 59, 59, 999);
    let costReportField = [
      // Satış Maliyet Raporu
      "startedDate",
      "endDate",
      "branchCode",
      "selectedGroup",
      "isSummary",
      "isServiceFee",
      "includeVariation",
      "includeTax",
      "stock_code",
      "licanceNo",
    ];
    let obj: Partial<RequestData> = {};
    Object.entries(this.glb.mainRequestData).forEach(([key, value]) => {
      if (costReportField.includes(key)) obj[key] = value;
    });
    return new Promise((resolve, reject) => {
      this.cs
        .serviceRequest(
          "/api/Report/getCostReport",
          {
            requestModel: {
              ...obj,
              endDate: endDate,
              selectedGroup: obj.selectedGroup?.join(","),
              serviceTypes: obj.serviceTypes?.join(","),
              stock_code: this.glb.mainRequestData.stock_code?.join(","),
            } as RequestData,
            licanceNo: parseInt(this.us.activeLicance.licanceId),
          },
          this.us.user.accessToken
        )
        .toPromise()
        .then(
          (o) => {
            console.log("getCostReport", Object.assign(o));
            this.salesCostData = Object.assign(o);
            resolve(Object.assign(o));
          },
          (e) => {
            if (e.message == "Timeout has occurred") {
              this.glb.pushToast("İstek Zamana Aşımına Uğradı", "error", 2000);
            } else {
              this.glb.pushToast(e.message, "error", 2000);
            }
            this.glb.loadingVisible = false;
          }
        );
    });
  }

  getSalesReportByServiceType() {
    let salesRaportField = [
      "startedDate",
      "endDate",
      "selectedGroup",
      "serviceTypes",
      "stock_code",
      "consolidated",
    ];

    let obj: Partial<RequestData> = {};
    Object.entries(this.glb.mainRequestData).forEach(([key, value]) => {
      if (salesRaportField.includes(key)) obj[key] = value;
    });

    return new Promise((resolve, reject) => {
      this.cs
        .serviceRequest(
          "/api/folio/getSalesReportByServiceTypes",
          {
            requestModel: {
              ...this.glb.mainRequestData,
              selectedGroup: obj.selectedGroup?.join(","),
              serviceTypes: obj.serviceTypes?.join(","),
              stock_code: this.glb.mainRequestData.stock_code.join(","),
            } as RequestData,
            licanceNo: parseInt(this.us.activeLicance.licanceId),
          },
          this.us.user.accessToken
        )
        .toPromise()
        .then(
          (o) => {
            console.log("salesReport", Object.assign(o));
            this.salesReportByServiceType = Object.assign(o);
            resolve(Object.assign(o));
          },
          (e) => {
            if (e.message == "Timeout has occurred") {
              this.glb.pushToast("İstek Zamana Aşımına Uğradı", "error", 2000);
            } else {
              this.glb.pushToast(e.message, "error", 2000);
            }
            this.glb.loadingVisible = false;
          }
        );
    });
  }

  getVatTurnoverReport() {
    let vatTurnoverReport = ["startedDate", "endDate", "branchCode"];

    let obj: Partial<RequestData> = {};
    Object.entries(this.glb.mainRequestData).forEach(([key, value]) => {
      if (vatTurnoverReport.includes(key)) obj[key] = value;
    });

    return new Promise((resolve, reject) => {
      this.cs
        .serviceRequest(
          "/api/Report/getVatTurnoverReport",
          {
            requestModel: {
              ...obj,
            } as RequestData,
            licanceNo: parseInt(this.us.activeLicance.licanceId),
          },
          this.us.user.accessToken
        )
        .toPromise()
        .then(
          (o) => {
            console.log("salesReport", Object.assign(o));
            this.vatTurnoverReports = Object.assign(o);
            resolve(Object.assign(o));
          },
          (e) => {
            if (e.message == "Timeout has occurred") {
              this.glb.pushToast("İstek Zamana Aşımına Uğradı", "error", 2000);
            } else {
              this.glb.pushToast(e.message, "error", 2000);
            }
            this.glb.loadingVisible = false;
          }
        );
    });
  }

  getWastageStaffReport() {
    let fields = [
      "startedDate",
      "endDate",
      "warehouse",
      "selectedGroup",
      "useType",
      // "reportType",
      "stock_code",
      "reportLevel",
      "showDetail",
      "rawMaterial",
      "reasonType",
    ];

    let obj: Partial<RequestData> = {};
    Object.entries(this.glb.mainRequestData).forEach(([key, value]) => {
      if (fields.includes(key)) obj[key] = value;
    });

    return new Promise((resolve, reject) => {
      this.cs
        .serviceRequest(
          "/api/Report/getWastageStaffReport",
          {
            requestModel: {
              ...obj,
              selectedGroup: obj.selectedGroup?.join(","),
              // serviceTypes: obj.serviceTypes?.join(","),
              stock_code: this.glb.mainRequestData.stock_code.join(","),
              useType: obj.useType?.join(","),
              warehouse: obj.warehouse?.join(","),
              reasonType: obj.reasonType?.join(","),
            } as RequestData,
            licanceNo: parseInt(this.us.activeLicance.licanceId),
          },
          this.us.user.accessToken
        )
        .toPromise()
        .then(
          (o) => {
            console.log("wastageStaffReports", Object.assign(o));
            this.wastageStaffReports = Object.assign(o);
            resolve(Object.assign(o));
          },
          (e) => {
            if (e.message == "Timeout has occurred") {
              this.glb.pushToast("İstek Zamana Aşımına Uğradı", "error", 2000);
            } else {
              this.glb.pushToast(e.message, "error", 2000);
            }
            this.glb.loadingVisible = false;
          }
        );
    });
  }

  getInventoryReport() {
    return new Promise((resolve, reject) => {
      let isSummary = this.glb.mainRequestData.isSummary;
      this.cs
        .serviceRequest(
          "/api/inventory/getInventoryReport",
          {
            ...this.glb.mainRequestData,
            startDate: this.glb.mainRequestData.startedDate,
            group: this.glb.mainRequestData.selectedGroup.join(","),
            warehouseCode: this.glb.mainRequestData.warehouse.join(","),
            branchCode: this.glb.mainRequestData.branchCode.join(","),
            stock_code: this.glb.mainRequestData.stock_code.join(","),
            licanceNo: parseInt(this.us.activeLicance.licanceId),
          } as RequestData,
          this.us.user.accessToken
        )
        .toPromise()
        .then(
          (o: any) => {
            console.log("getInventoryReport", Object.assign(o));
            if (Array.isArray(o)) {
              this.inventoryReport = o.map((elm) =>
                isSummary == 0
                  ? {
                      ISLETME_ADI: elm.ISLETME_ADI,
                      BOLGE_ADI: elm.BOLGE_ADI,
                      DEPO_KODU: elm.DEPO_KODU,
                      DEPO_ADI: elm.DEPO_ADI,
                      ANA_GRUP: elm.ANA_GRUP,
                      GRUP: elm.GRUP,
                      STOK_KODU: elm.STOK_KODU,
                      STOK_ADI: elm.STOK_ADI,
                      BIRIM: elm.BIRIM,
                      BASLANGIC_MIKTAR: elm.BASLANGIC_MIKTAR,
                      BASLANGIC_MALIYET: elm.BASLANGIC_MALIYET,
                      FATURA_GIRIS_MIKTAR: elm.FATURA_GIRIS_MIKTAR,
                      FATURA_GIRIS_MALIYET: elm.FATURA_GIRIS_MALIYET,
                      FATURA_CIKIS_MIKTAR: elm.FATURA_CIKIS_MIKTAR,
                      FATURA_CIKIS_MALIYET: elm.FATURA_CIKIS_MALIYET,
                      TRANSFER_GIRIS_MIKTAR: elm.TRANSFER_GIRIS_MIKTAR,
                      TRANSFER_GIRIS_MALIYET: elm.TRANSFER_GIRIS_MALIYET,
                      TRANSFER_CIKIS_MIKTAR: elm.TRANSFER_CIKIS_MIKTAR,
                      TRANSFER_CIKIS_MALIYET: elm.TRANSFER_CIKIS_MALIYET,
                      IDEAL_TUKETIM_MIKTAR: elm.IDEAL_MIKTAR,
                      IDEAL_TUKETIM_MALIYET: elm.IDEAL_MALIYET,
                      FIRE_PERSONEL_MIKTAR: elm.FIREPER_MIKTAR,
                      FIRE_PERSONEL_MALIYET: elm.FIREPER_MALIYET,
                      URETIM_MIKTAR: elm.URETIM_MIKTAR,
                      URETIM_MALIYET: elm.URETIM_MALIYET,
                      PARCALAMA_MIKTAR: elm.PARCALAMA_MIKTAR,
                      PARCALAMA_MALIYET: elm.PARCALAMA_MALIYET,
                      HESAPLANAN_MIKTAR: elm.HESAPLANAN_MIKTAR,
                      HESAPLANAN_MALIYET: elm.HESAPLANAN_MALIYET,
                      KALAN: elm.KALAN,
                      KALAN_MALIYET: elm.KALAN_MALIYET,
                      GERCEK_TUKETIM_MIKTAR: elm.GERCEK_TUKETIM_MIKTAR,
                      GERCEK_TUKETIM_MALIYETI: elm.GERCEK_TUKETIM_MALIYETI,
                      FARK_MIKTAR:
                        elm.KALAN && elm.HESAPLANAN_MIKTAR
                          ? +elm.KALAN - +elm.HESAPLANAN_MIKTAR
                          : 0,
                      FARK_MALIYET:
                        elm.KALAN_MALIYET && elm.HESAPLANAN_MALIYET
                          ? +elm.KALAN_MALIYET - +elm.HESAPLANAN_MALIYET
                          : 0,
                    }
                  : { ...elm }
              );
            }
            resolve(Object.assign(o));
          },
          (e) => {
            if (e.message == "Timeout has occurred") {
              this.glb.pushToast("İstek Zamana Aşımına Uğradı", "error", 2000);
            } else {
              this.glb.pushToast(e.message, "error", 2000);
            }
            this.glb.loadingVisible = false;
          }
        );
    });
  }

  getInvoiceList() {
    return new Promise((resolve, reject) => {
      this.cs
        .serviceRequest(
          "/api/invoice/getInvoiceList",
          {
            ...this.glb.mainRequestData,
            selectedGroup: this.glb.mainRequestData.selectedGroup.join(","),
            warehouse: this.glb.mainRequestData.warehouse.join(","),
            stock_code: this.glb.mainRequestData.stock_code.join(","),
            recortType: this.glb.mainRequestData.recortType.join(","),
            branchCode: this.glb.mainRequestData.branchCode.join(","),
            invoiceType: this.glb.mainRequestData.invoiceType.join(","),
            accountCode: this.glb.mainRequestData.accountCode.join(","),
            licanceNo: parseInt(this.us.activeLicance.licanceId),
          } as RequestData,
          this.us.user.accessToken
        )
        .toPromise()
        .then(
          (o: any[]) => {
            this.invoiceReport = o.map((elm) => {
              Object.keys(elm).forEach((key) => {
                if (key.startsWith("HAR_") || key.startsWith("FB_")) {
                  elm[key.replace("HAR_", "").replace("FB_", "")] = elm[key];
                  delete elm[key];
                }
                return elm;
              });

              return elm;
            });

            console.log("getInvoiceList", this.invoiceReport);

            resolve(Object.assign(o));
          },
          (e) => {
            if (e.message == "Timeout has occurred") {
              this.glb.pushToast("İstek Zamana Aşımına Uğradı", "error", 2000);
            } else {
              this.glb.pushToast(e.message, "error", 2000);
            }
            this.glb.loadingVisible = false;
          }
        );
    });
  }
}
