
<div class="page-wrapper" [ngClass]="{'toggled' : getSideBarState()}">
  <app-sidebar></app-sidebar>

  <main class="page-content" style="float: left;">
      <div class="container-fluid">   
        <app-breadcrumb></app-breadcrumb>  
        <router-outlet></router-outlet>
      <div class="overlay" (click)="toggleSidebar()" [ngClass]="{'show' : !getSideBarState()}"></div>
     </div>        
  </main>   
  <app-request-form></app-request-form>
</div>


<dx-popup [height]="600" [width]="this.glb.setPopupWidth()" [showTitle]="true" [dragEnabled]="false"
	[closeOnOutsideClick]="false" [(visible)]="this.glb.licancePopupVisible" title="Lisans Seçiniz">
	<div *dxTemplate="let data of 'content'">
		<dx-scroll-view width="100%" height="100%">
			<app-licance-list ></app-licance-list>
		</dx-scroll-view>
	</div>
</dx-popup>
