import { Component, OnInit } from "@angular/core";
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from "@angular/animations";

import { Router } from "@angular/router";
import { confirm } from "devextreme/ui/dialog";
import { SidebarService } from "./services/sidebar.service";
import { UserService } from "src/app/services/user.service";
import { GlobalService } from "src/app/services/global.service";
import { ReportService } from "src/app/services/report.service";
import { RequestData } from "src/app/models/request-form-model";

// import { MenusService } from './menus.service';
var that;
@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
  animations: [
    trigger("slide", [
      state("up", style({ height: 0 })),
      state("down", style({ height: "*" })),
      transition("up <=> down", animate(200)),
    ]),
  ],
})
export class SidebarComponent implements OnInit {
  menus = [];
  userName = "";
  userSurName = "";
  constructor(
    public sidebarservice: SidebarService,
    public us: UserService,
    public _router: Router,
    public glb: GlobalService,
    public rs: ReportService
  ) {
    this.menus = sidebarservice.getMenuList();
    that = this;
  }

  ngOnInit() {}

  getSideBarState() {
    return this.sidebarservice.getSidebarState();
  }

  selectLicance() {
    this.glb.licancePopupVisible = true;
  }

  toggle(currentMenu) {
    if (currentMenu.type === "dropdown") {
      this.menus.forEach((element) => {
        if (element === currentMenu) {
          currentMenu.active = !currentMenu.active;
        } else {
          element.active = false;
        }
      });
    }
  }

  get filtereddMenus() {
    if(+this.us.activeLicance.licanceId == 930) 
      return this.menus;
    else return this.menus.filter((element) => element.reportName !== 'branchAnalysisReport')
  }

  async getReport(reportName) {
    let temp_Text = this.glb.loadingText;
    this.glb.loadingText = 'Parametreler Yükleniyor...';
    this.glb.loadingVisible = true;
    console.log(this.rs.products);
    this.glb.activeReport = reportName
    if (reportName == 'CostReport')
    {
      this.rs.filtredProducts = this.rs.products.filter(x=> x.P_TYPE == 'P')
    }
    else
    {
      this.rs.filtredProducts = this.rs.products
    }
    console.log("selectedReport",reportName)
    console.log("glbselectedreport",this.glb.selectedRepoort)
    if (this.glb.selectedRepoort != reportName) {
      if (this.rs.productGroups.length == 0) {
        this.rs.getProductGroups();
      }
      await this.rs.getWareHouses(
        [...this.rs.branchList.map((el) => el.SUBE_KODU)].join(",")
      );
      if (
        this.glb.mainRequestData.selectedGroup?.length !=
        this.rs.productGroups.length
      ) {
        await this.rs.getProductList(
          [...this.rs.productGroups.map((el) => el.Kimlik)].join(",")
        );
      }
      if (this.rs.accounts.length == 0) {
        await this.rs.getAccountList();
      }
      await this.rs.getServiceTypes();
      this.glb.mainRequestData = {
        ...this.glb.mainRequestData,
        startedDate: new Date(),
        endDate: new Date(),
        branchCode: [...this.rs.branchList?.map((el) => el.SUBE_KODU)],
        warehouse: [...this.rs.wareHouses?.map((el) => el.DEPO_KODU)],
        selectedGroup: [...this.rs.productGroups?.map((el) => el.Kimlik)],
        stock_code: [...this.rs.products?.map((el) => el.STOK_KODU)],
        invoiceType: [...this.glb.getinvoiceTypes()?.map((el) => el.value)],
        accountCode: [...this.rs.accounts?.map((el) => el.CARI_KODU)],
        recortType: [...this.glb.getRecordTypes()?.map((el) => el.value)],
        selecetedProduct: [...this.rs.products?.map((el) => el.STOK_KODU)],
        serviceTypes: [...this.rs.serviceTypes?.map((el) => el.KOD)],
        counting: 1,
        consolidated: 1,
        groupType: 1,
        includeTax: 1,
        includeVariation: 1,
        isConsolidated: 1,
        isServiceFee: 1,
        isSummary: 1,
        param: 1,
        parameter: 1,
        vatInclude: 1,
        useType: ['O', 'M'],
        reportType: 0
      };
      this._router.navigateByUrl("/layout");
      this.glb.selectedRepoort = reportName;
      this.glb.loadingVisible = false;
      this.glb.loadingText = temp_Text;
    }
  }

  toggleSidebar() {
    this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState());
  }

  getState(currentMenu) {
    if (currentMenu.active) {
      return "down";
    } else {
      return "up";
    }
  }

  hasBackgroundImage() {
    return this.sidebarservice.hasBackgroundImage;
  }

  logout() {
    var result = confirm(
      "Sistemden Çıkış Yapmak İstediğinize Emin misiniz?",
      "Çıkış"
    );
    result.then(function (dialogResult) {
      if (dialogResult) {
        that.us.logout();
      }
    });
  }
}
