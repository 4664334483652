<div class="row" style="margin-bottom: 20px;">
    <div class="col-12">
      <button (click)="exportExcel()" style="float: right;" type="button" class="btn btn-success">
        <span class="material-icons">
          get_app
          </span>
      </button>
      <button (click)="exportToPDF()" style="float: right; margin-right: 5px;" type="button" class="btn btn-primary">
        <span class="material-icons">
          picture_as_pdf
          </span>
      </button>
    </div>
  </div>


<div class="container shadow" style="margin-bottom: 30px;">
    <div class="col-12">
        <table class="table table-hover" style="margin-bottom: 20px;" id="contentToConvert">
            <thead>
                <tr>
                    <th style="color:#4C52BC" scope="col">Şube Adı</th>
                    <th style="color:#4C52BC" scope="col">Adet</th>
                    <th style="color:#4C52BC" scope="col">Ödenmez Tutarı</th>
                    <th style="color:#4C52BC" scope="col">Adisyon Toplamı</th>
                    <!-- <th style="color:#4C52BC" scope="col">Maliyet</th> -->
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of this.rs.paylessReportData;let c=index">
                    <td><a (click)="getPaylessDetail()"><span style="text-decoration: underline;cursor: pointer;">{{item.SUBE_ADI}}</span></a></td>
                    <td>{{item.adet}}</td>
                    <td>{{item.miktar | currency}}</td>
                    <td>{{item.yekun | currency}}</td>
                    <!-- <td>{{item.MALIYET | currency}}</td> -->
                </tr>
            </tbody>
        </table>
    </div>
</div> 
