import { Injectable } from "@angular/core";
import notify from "devextreme/ui/notify";
import { IdNameType, OnoffModel } from "../models/global-model";
import { RequestData } from "../models/request-form-model";

let onOffModel: OnoffModel[] = [
  {
    name: "Göster",
    value: 1,
  },
  {
    name: "Gizle",
    value: 0,
  },
];

let includeTaxTypes: IdNameType[] = [
  {
    name: "KDV Dahil",
    id: 0,
  },
  {
    name: "KDV Hariç",
    id: 1,
  },
];

let consolidateTypes: any[] = [
  {
    id: "0",
    name: "Şube Bazında Ürün Detaylı Göster",
  },
  {
    id: 1,
    name: "Şubeleri Konsolide Ürün Detaylı Göster",
  },
  {
    id: 2,
    name: "Şubeler Bazında Servis Tipine Göre Özet",
  },
];

let countingTypes: IdNameType[] = [
  {
    id: 1,
    name: "Günlük",
  },
  {
    id: 2,
    name: "Haftalık",
  },
  {
    id: 3,
    name: "Aylık",
  },
  {
    id: 4,
    name: "Tüm Stoklar",
  },
];

let konsolideTypesInventory = [
  {
    id: "0",
    name: "Depolar ayrı ayrı",
  },
  {
    id: 1,
    name: "Depolar konsalide",
  },
];

let parameterTypes: IdNameType[] = [
  {
    id: 1,
    name: "Tedarikçi Bazında İskonto Raporu",
  },
  {
    id: 3,
    name: "Toplam Alım Raporu",
  },
  {
    id: 4,
    name: "(Özet) Cari ve Döküman Bazında Alım Raporu",
  },
  {
    id: 5,
    name: "(Detaylı) Cari ve Döküman Bazında Alım Raporu",
  },
];

let costSummary: OnoffModel[] = [
  {
    name: "Ozet",
    value: 1,
  },
  {
    name: "detay",
    value: 0,
  },
];

let invoiceTypes: any[] = [
  {
    name: "Alış Faturası",
    value: 0,
  },
  {
    name: "Satış Faturası",
    value: 1,
  },
  {
    name: "Alış İrsaliyesi",
    value: 2,
  },
  {
    name: "Satış İrsaliyesi",
    value: 3,
  },
];

let recordTypes: any[] = [
  {
    name: "Açık",
    value: 0,
  },
  {
    name: "Kapalı",
    value: 1,
  },
  {
    name: "İade Açık",
    value: 2,
  },
  {
    name: "İade Kapalı",
    value: 3,
  },
];

let useTypes: any[] = [
  {
    name: "Fire Zayi işlemleri",
    value: "O",
  },
  {
    name: "Malzeme Personel Çıkışı",
    value: "M",
  },
];

let reportTypes: IdNameType[] = [
  {
    id: 0,
    name: "Tarihler ve Depolar Detaylı",
  },
  {
    id: 1,
    name: "Tarihler Konsolide - Depolar Detaylı",
  },
  {
    id: 4,
    name: "Tarihler Detaylı - Depolar Konsolide",
  },
  {
    id: 5,
    name: "Tarihler ve Depolar Konsolide",
  },
];

let reportLevelTypes: IdNameType[] = [
  {
    id: 0,
    name: "Fiş No Bazında Detaylı",
  },
  {
    id: 1,
    name: "Ürün Bazında Detaylı",
  },
  {
    id: 2,
    name: "Gruplar Bazında Detaylı",
  },
  {
    id: 3,
    name: "Ana Gruplar Bazında Detaylı",
  },
  {
    id: 4,
    name: "Sebepler Bazında",
  },
  {
    id: 5,
    name: "Malzeme Çıkış Sebebi Bazında",
  },
  {
    id: 6,
    name: "Depolar Bazında",
  },
  {
    id: 7,
    name: "Ürünler Bazında Özet",
  },
  {
    id: 8,
    name: "Gruplar Bazında Özet",
  },
  {
    id: 9,
    name: "Ana Gruplar Bazında Özet",
  },
];


let goruntulenmeTypes: IdNameType[] = [
  {
    id: 0,
    name: "Sayfa Rapor",
  },
  {
    id: 1,
    name: "Excel Grid Report",
  }
];

let hammaddeTypes: IdNameType[] = [
  {
    id: 0,
    name: "ÜRÜN BAZLI",
  },
  {
    id: 1,
    name: "STOK DETAYLI",
  }
];

@Injectable({
  providedIn: "root",
})
export class GlobalService {
  userToken: string = "";
  loadingText: string = "";
  loadingVisible: boolean = false;
  indicatorUrl: string =
    "https://js.devexpress.com/Content/data/loadingIcons/rolling.svg";
  apiKey: string = "1b85ccb4-b770-50ff-dc1e-d50498081e0d!?@a2d6!7b35**8527";
  userServiceUrl: string = "https://sso-service.kerzz.com:4500";
  licancePopupVisible: boolean = false;
  selectedRepoort: string = "";
  activeReport : string = ""
  consiledType: number = 0;
  mainRequestData: RequestData = new RequestData();
  constructor() {}

  pushToast(
    msg: string,
    color: "error" | "warning" | "success",
    duration: number
  ) {
    notify(msg, color, duration);
  }

  getOnOff() {
    return onOffModel;
  }

  getSummaryParam() {
    return costSummary;
  }

  getconsolidateTypes() {
    return consolidateTypes;
  }

  getcountingTypes() {
    return countingTypes;
  }

  getinvoiceTypes() {
    return invoiceTypes;
  }

  getkonsolideForInventory() {
    return konsolideTypesInventory;
  }

  getRecordTypes() {
    return recordTypes;
  }

  getParameterTypes() {
    return parameterTypes;
  }

  getReportTypes() {
    return reportTypes;
  }

  getUseTypes() {
    return useTypes;
  }

  getreportLevelTypes() {
    return reportLevelTypes;
  }

  getGoruntulenmeTypes() {
    return goruntulenmeTypes;
  }

  gethammadeTypes() {
    return hammaddeTypes;
  }

  getIncludeTaxTypes() {
    return includeTaxTypes;
  }

  setPopupWidth() {
    if (screen.width >= 576) {
      return "550px";
    } else {
      return "auto";
    }
  }
}
