import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { ExcelService } from 'src/app/services/excel.service';
import { GlobalService } from 'src/app/services/global.service';
import { ReportService } from 'src/app/services/report.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-wastage-staff-report',
  templateUrl: './wastage-staff-report.component.html',
  styleUrls: ['./wastage-staff-report.component.scss']
})
export class WastageStaffReportComponent implements OnInit {

  constructor(
    public rs: ReportService,
    public us: UserService,
    public glb: GlobalService,
    private nav: Router,
    private exl: ExcelService
  ) {
    console.log("ada", this.rs.paylessReportDetailData);
  }

  exportExcel() {
    this.glb.loadingText = "Excel'e Aktarılıyor...";
    this.glb.loadingVisible = true;
    setTimeout(() => {
      this.exl.exportAsExcelFile(
        this.formatDate(this.rs.wastageStaffReports),
        "Fire Zayi Personel Raporu"
      );
      this.glb.loadingVisible = false;
      this.glb.pushToast("Excel Aktarımı Tamamlandı.", "success", 2000);
    }, 2000);
  }

  formatDate(array: any[]) {
    let DateFields = ["TARIH", "FIILI_TARIH", "ODEME_TARIHI"];
    return array.map(elm => {
      Object.keys(elm).forEach(key => {
        if(DateFields.includes(key)) {
          let date = new Date(elm[key]);
          elm[key] = `${("0" + date.getDate()).slice(-2)}/${("0" + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()}`
        }
      });

      return elm
    })
  }

  exportToPDF() {
    this.glb.loadingText = "PDF Aktarılıyor...";
    this.glb.loadingVisible = true;
    var data = document.getElementById("contentToConvert");
    html2canvas(data).then((canvas) => {
      var imgData = canvas.toDataURL("image/png");
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;

      var doc = new jsPDF("p", "mm");
      var position = 0;

      doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      doc.save("Fatura Raporu.pdf");
      this.glb.loadingVisible = false;
    });
  }

  customizeColumns(e: any[]) {
    // if (e.rowType === "data" && e.column.dataField === "FARK_MALIYET") {
    //   return this.currencyPipe.transform(e.data.FARK_MALIYET, 'TRY');
    // }
    let DateFields = ["TARIH", "FIILI_TARIH", "ODEME_TARIHI"];
    let fields = e.filter((elm) => DateFields.includes(elm.dataField));
    for (const field of fields) {
      field.format = 'dd/MM/yyyy';
      field.dataType = 'date';
      
    }
 
  }
 

  ngOnInit(): void {}

}
