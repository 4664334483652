<dx-pivot-grid
[allowSortingBySummary]="true"
[allowSorting]="true"
[allowExpandAll]="true"
[allowFiltering]="true"
[showBorders]="true"
[height]="570"
[dataSource]="this.reportService.branchAnaylsisReport"
>
<dxo-field-panel [visible]="true"></dxo-field-panel>
<dxo-field-chooser [enabled]="true"></dxo-field-chooser>

<dxo-state-storing
  [enabled]="this.reportService.branchAnaylsisReport.length > 0"
  type="localStorage"
  storageKey="dx-widget-gallery-pivotgrid-storing"
>
</dxo-state-storing>
</dx-pivot-grid>