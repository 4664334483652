<div class="row" style="margin-bottom: 20px">
  <div class="col-12">
    <button
      (click)="exportToPDF()"
      style="float: right; margin-right: 5px"
      type="button"
      class="btn btn-primary"
    >
      <span class="material-icons"> picture_as_pdf </span>
    </button>
  </div>
</div>

<div class="container shadow" style="margin-bottom: 30px">
  <div class="col-12" id="contentToConvert">
    <dx-data-grid
      id="gridContainer"
      [dataSource]="this.rs.salesCostData"
      [allowColumnResizing]="true"
      [showBorders]="true"
      (onExporting)="onExporting($event)"
    >
      <dxo-grouping [contextMenuEnabled]="true"> </dxo-grouping>
      <dxo-export [enabled]="true"></dxo-export>
      <dxo-group-panel [visible]="true">
        <!-- or "auto" -->
      </dxo-group-panel>
      <dxo-paging [pageSize]="10"></dxo-paging>
      <dxo-editing
        mode="row"
        [allowAdding]="false"
        [allowUpdating]="false"
        [allowDeleting]="false"
        [useIcons]="true"
      >
      </dxo-editing>
      <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
      <dxo-pager
        [showPageSizeSelector]="true"
        [allowedPageSizes]="[5, 10, 20]"
        [showInfo]="true"
      >
      </dxo-pager>

      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-pager
        [showPageSizeSelector]="true"
        [allowedPageSizes]="[10, 25, 50, 100]"
        [showInfo]="true"
      >
      </dxo-pager>
      <dxo-search-panel
        [visible]="true"
        [highlightCaseSensitive]="true"
      ></dxo-search-panel>

      <dxi-column dataField="SUBE_ADI" caption="Şube Adı"> </dxi-column>
      <dxi-column
        dataField="STOK_KODU"
        dataType="string"
        caption="Stok Kodu"
      ></dxi-column>
      <dxi-column
        dataField="URUN"
        dataType="string"
        caption="Ürün Adı"
      ></dxi-column>
      <dxi-column dataField="BIRIM" caption="Birim"> </dxi-column>
      <dxi-column
        dataField="MIKTAR"
        dataType="number"
        caption="Miktar"
      ></dxi-column>
      <dxi-column
        dataField="GRUP"
        dataType="string"
        caption="Grup"
      ></dxi-column>
      <dxi-column
        dataField="BIRIM_FIYAT"
        format="₺ #,###.00"
        dataType="number"
        caption="Birim Fiyat"
      ></dxi-column>
      <dxi-column
        dataField="FIYAT"
        format="₺ #,###.00"
        dataType="number"
        caption="Fiyat"
      ></dxi-column>
      <dxi-column
        dataField="KAR_TUTAR"
        format="₺ #,###.00"
        dataType="number"
        caption="Kar Tutar"
      ></dxi-column>
      <!-- <dxi-column dataField="MALIYET"  format="₺ #,###.00" dataType="number"  caption="Maliyet2"></dxi-column>   -->
      <dxi-column
        dataField="MALIYET"
        caption="Maliyet"
        cellTemplate="totalCellTemplate"
        format="₺ #,###.00"
      >
        <div *dxTemplate="let item of 'totalCellTemplate'">
          {{ +item.data?.MALIYET * +item.data?.MIKTAR | number: "1.2-2" }}
        </div>
      </dxi-column>
      <dxi-column
        dataField="MALIYET_ORANI"
        dataType="number"
        format="##.##%"
        dataType="number"
        caption="Maliyet Oranı(%)"
        [calculateCellValue]="calculatePercentCell"
      ></dxi-column>
      <dxo-summary [calculateCustomSummary]="calculateTotalMaliyet">
        <dxi-total-item
          name="allMaliyetColumn"
          summaryType="custom"
          showInColumn="MALIYET"
          valueFormat="#,###.00"
          displayFormat="{0}"
        >
        </dxi-total-item>
      </dxo-summary>
    </dx-data-grid>
  </div>
</div>
