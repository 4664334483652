<div class="row" style="margin-bottom: 20px;">
    <div class="col-12">
      <button (click)="exportExcel()" style="float: right;" type="button" class="btn btn-success">
        <span class="material-icons">
          get_app
          </span>
      </button>
      <button (click)="exportToPDF()" style="float: right; margin-right: 5px;" type="button" class="btn btn-primary">
        <span class="material-icons">
          picture_as_pdf
          </span>
      </button>
  
    </div>
  </div>

<div class="container shadow" style="margin-bottom: 30px;">
    <div class="col-12">
        <table class="table table-hover" style="margin-bottom: 20px;" id="contentToConvert">
            <thead>
                <tr>
                    <th style="color:#4C52BC" scope="col">Sıra No</th>
                    <th style="color:#4C52BC" scope="col">Sube</th>
                    <th style="color:#4C52BC" scope="col">Çek No</th>
                    <th style="color:#4C52BC" scope="col">Ürün Kodu</th>
                    <th style="color:#4C52BC" scope="col">Ürün Adı</th>
                    <th style="color:#4C52BC" scope="col">Grup</th>
                    <th style="color:#4C52BC" scope="col">Miktar</th>
                    <th style="color:#4C52BC" scope="col">Toplam</th>
                    <th style="color:#4C52BC" scope="col">Silme Neden</th>
                    <th style="color:#4C52BC" scope="col">Açıklama</th>
                  
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of this.rs.deletedItemData;let c=index">
                    <td>{{item.SIRA_NO}}</td>
                    <td>{{item.SUBE_ADI}}</td>
                    <td style="cursor: pointer!important;text-decoration: underline;"><a (click)="getFolioDetail(item.Adisyonno)">{{item.Adisyonno}}</a></td>
                    <td>{{item.STOK_KODU}}</td>
                    <td>{{item.Urun_Adi}}</td>
                    <td>{{item.Grup}}</td>
                    <td>{{item.miktari}}</td>
                    <td style="text-align: center;">{{item.Toplam | currency}}</td>
                    <td>{{item.NEDEN}}</td>
                    <td>{{item.ACIKLAMA}}</td>  
                </tr>
            </tbody>
        </table>
    </div>
</div> 
