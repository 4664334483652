<div class="row" style="margin-bottom: 20px">
    <div class="col-12">
      <button
        (click)="exportExcel()"
        style="float: right"
        type="button"
        class="btn btn-success"
      >
        <span class="material-icons"> get_app </span>
      </button>
      <!-- <button
        (click)="exportToPDF()"
        style="float: right; margin-right: 5px"
        type="button"
        class="btn btn-primary"
      >
        <span class="material-icons"> picture_as_pdf </span>
      </button> -->
    </div>
  </div>
  
  <div>
    <dx-data-grid
      [columnAutoWidth]="true"
      [columnHidingEnabled]="false"
      [scrolling]="{ showScrollbar: 'always' }"
      [columnHidingEnabled]="true"
      [dataSource]="rs.wastageStaffReports"
      [customizeColumns]="customizeColumns"
    >
      <dxo-column-fixing [enabled]="false"></dxo-column-fixing>
  
      <dxo-column-chooser [enabled]="true" mode="select"> </dxo-column-chooser>
    </dx-data-grid>
  </div>
  
  <div class="container shadow" style="margin-bottom: 30px; display: none">
    <div class="col-12">
      <table
        class="table table-hover"
        style="margin-bottom: 20px"
        id="contentToConvert"
      >
        <thead>
          <tr>
            <th style="color: #4c52bc" scope="col">Ana Grup</th>
            <th style="color: #4c52bc" scope="col">Grup</th>
            <th style="color: #4c52bc" scope="col">Stok Kodu</th>
            <th style="color: #4c52bc" scope="col">Stok Adı</th>
            <th style="color: #4c52bc" scope="col">Temel Birimi</th>
            <th style="color: #4c52bc" scope="col">Miktar</th>
            <th style="color: #4c52bc" scope="col">KDV Hariç</th>
            <th style="color: #4c52bc" scope="col">KDV Dahil</th>
            <th style="color: #4c52bc" scope="col">KDV Oranı</th>
            <th style="color: #4c52bc" scope="col">KDV Tutarı</th>
            <th style="color: #4c52bc" scope="col">Oran</th>
          </tr>
        </thead>
  
        <tbody>
          <tr *ngFor="let item of rs.invoiceReport; let c = index">
            <td>{{ item.ANA_GRUP }}</td>
            <td>{{ item.GRUP }}</td>
            <td>{{ item.STOK_KODU }}</td>
            <td>{{ item.STOK_ADI }}</td>
            <td>{{ item.TEMEL_BIRIMI }}</td>
            <td>{{ item.MIKTAR }}</td>
            <td>{{ item.KDV_HARIC }}</td>
            <td>{{ item.KDV_DAHIL }}</td>
            <td>{{ item.KDV_ORANI }}</td>
            <td>{{ item.KDV_TUTARI | currency }}</td>
            <td>{{ item.ORAN | currency }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  