<div class="row" style="margin-bottom: 20px;">
  <div class="col-12">     
    <button (click)="exportToPDF()" style="float: right; margin-right: 5px;" type="button" class="btn btn-primary">
      <span class="material-icons">
        picture_as_pdf
        </span>
    </button>

  </div>
</div>


<div class="container shadow" style="margin-bottom: 30px;" id="contentToConvert" >
    <div id="invoice" style="font-size:10px"  *ngIf="this.rs.folioDetail.length > 0">

        <div class="invoice overflow-auto" style="margin-top: 17px;">
          <div style="min-width: 600px">
            <header>
              <div class="row">
                <div class="col">
      
                </div>
                <div class="col company-details">
                  <h2 class="name">
      
                    ŞUBE : {{this.rs.folioDetail[0].SUBE_ADI}}
      
                  </h2>
                </div>
              </div>
            </header>
            <main>
              <div class="row contacts">
                <div class="col invoice-to">
                  <div class="text-gray-light">PERSONEL :</div>
                  <h4 >{{this.rs.folioDetail[0].PERSONEL}}</h4>
                  <div class="text-gray-light">MASA :</div>
                  <h4 >{{this.rs.folioDetail[0].masano}}</h4>
                  <div class="address">Kişi Sayısı : {{this.rs.folioDetail[0].KISI_SAYISI}}</div>
                  <div class="address">Oturma Süresi : {{this.rs.folioDetail[0].OTURMA_SURESI}} dk</div>
                </div>
                <div class="col invoice-details">
                  <h4 class="invoice-id">{{this.rs.folioDetail[0].Adisyonno}}</h4>
                  <div class="date">Açılış Zamanı: {{this.rs.folioDetail[0].ACILIS_ZAMANI | date :  "dd.MM.yyyy h:mm"}}</div>
                  <div class="date">Kapanış Zamanı: {{this.rs.folioDetail[0].KAPANIS_ZAMANI | date : "dd.MM.yyy h:mm"}}</div>
                </div>
              </div>
              <div class="col-12">
                <table class="table table-hover" style="margin-bottom: 20px;">
                    <thead>
                        <tr>
                          <th class="text-left" style="color:#4C52BC">Eklenme Zamanı</th>
                            <th class="text-left" style="color:#4C52BC">Ürün Adı</th>
                            <th class="text-left" style="color:#4C52BC" >Adet</th>
                            <th class="text-right" style="color:#4C52BC">Kdv</th>
                            <th class="text-right" style="color:#4C52BC">Matrah</th>
                            <th class="text-right" style="color:#4C52BC">Tutar</th>
                            <th class="text-right" style="color:#4C52BC">İkram</th>
                            <th class="text-right" style="color:#4C52BC">İkram Neden</th>
                            <th class="text-right" style="color:#4C52BC">Silinme</th>
                            <th class="text-right" style="color:#4C52BC">Silen</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of this.rs.folioDetail;let c=index">
                          <td class=" text-left">{{item.Tarih | date :  "dd.MM.yyyy h:mm"}}</td>
                            <td class=" text-left">{{item.urunadi}}</td>
                            <td class="qty text-left">{{item.ADET}}</td>
                            <td class="text-right">{{item.KDV | currency}}</td>
                            <td class="text-right">{{item.MATRAH | currency}}</td>
                            <td class="text-right">{{item.GELIR | currency}}</td>
                            <td class="text-right">{{item.IKRAM}}</td>
                            <td class="text-right">{{item.IKRAM_NEDEN}}</td>
                            <td class="text-right">{{item.SILINME_SEBEBI.substring(0, item.SILINME_SEBEBI.length - 15)}} <a (click)="selectDetail(item.SILINME_SEBEBI.slice(-15))" style="cursor: pointer;"><span style="text-decoration: underline; cursor: pointer;">{{item.SILINME_SEBEBI.slice(-15)}}</span> </a></td>
                            <td class="text-right">{{item.SILEN}}</td>
                          </tr>
                    </tbody>
                    <tfoot>
                      
                     
            
                        <tr>
                          <td></td>
                          <td colspan="8">KDV</td>
                          <td>{{this.rs.folioDetail[0].KDV_TOPLAM | currency}}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td colspan="8">MATRAH</td>
                          <td>{{this.rs.folioDetail[0].MATRAH_TOPLAM | currency}}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td colspan="8">ARA TOPLAM</td>
                          <td>{{this.rs.folioDetail[0].tutar | currency}}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td colspan="8">GARSONİYE</td>
                          <td>{{this.rs.folioDetail[0].GARSONIYE | currency}} </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td colspan="8">INDIRIM</td>
                          <td>{{this.rs.folioDetail[0].INDIRIM | currency}}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td colspan="8">INDIRIM ORANI</td>
                          <td>%{{100 - this.rs.folioDetail[0].INDIRIM_ORANI | number}}</td>
                        </tr>                                
                        <tr>
                          <td></td>
                          <td colspan="8">NET TOPLAM</td>
                          <td>{{(this.rs.folioDetail[0].tutar + this.rs.folioDetail[0].GARSONIYE ) - this.rs.folioDetail[0].INDIRIM | currency}}</td>
                        </tr>
                      </tfoot>
                </table>
            </div>
              <div class="notices">
                <div>ADİSYON NOT:</div>
                <div class="notice">{{this.rs.folioDetail[0].ADISYON_NOTU}}</div>
              </div>
              <div class="notices">
                <div>ÖDEME BİLGİSİ:</div>
                <div class="notice">{{this.rs.folioDetail[0].ODEME}}</div>                
              </div>
            </main>      
          </div>      
          <div></div>
        </div>
      </div>
</div>


