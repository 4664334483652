<div class="row" style="margin-bottom: 20px;">
    <div class="col-12">
      <button (click)="exportExcel()" style="float: right;" type="button" class="btn btn-success">
        <span class="material-icons">
          get_app
          </span>
      </button>
      <button (click)="exportToPDF()" style="float: right; margin-right: 5px;" type="button" class="btn btn-primary">
        <span class="material-icons">
          picture_as_pdf
          </span>
      </button>
    </div>
  </div> 

 <div class="container shadow" style="margin-bottom: 30px;">
    <div class="col-12">       
        <table class="table table-hover" style="margin-bottom: 20px;" id="contentToConvert">
           <thead>
                <tr>
                    <th style="color:#4C52BC" scope="col">Şube Adı</th>
                    <th style="color:#4C52BC" scope="col">Adisyon No</th>
                    <th style="color:#4C52BC" scope="col">Tarih</th>
                    <th style="color:#4C52BC" scope="col">Masa No</th>
                    <th style="color:#4C52BC" scope="col">Terminal</th>
                    <th style="color:#4C52BC" scope="col">Masa Açan</th>
                    <th style="color:#4C52BC" scope="col">Hesabı Kapatan</th>
                    <th style="color:#4C52BC" scope="col">Ödenmez Kişi</th>
                    <th style="color:#4C52BC" scope="col">Açıklama</th>
                    <th style="color:#4C52BC" scope="col">Ödenmez Tutarı</th>
                    <th style="color:#4C52BC" scope="col">Adisyon Toplamı</th>
                    <!-- <th style="color:#4C52BC" scope="col">Maliyet</th> -->
                </tr>
            </thead> 
       
           
      
         <tbody>
                <tr *ngFor="let item of this.rs.paylessReportDetailData;let c=index">
                    <td>{{item.SUBE_ADI}}</td>   
                    <td><a (click) = "getFolioDetail(item.adisyonno)"><span style="text-decoration: underline;cursor: pointer;">{{item.adisyonno}}</span></a></td>                                   
                    <td>{{item.TARIH}}</td>
                    <td>{{item.masano}}</td>
                    <td>{{item.Terminal}}</td>
                    <td>{{item.MASA_ACAN}}</td>
                    <td>{{item.HESABI_KAPATAN}}</td>
                    <td>{{item.ODENMEZ_KISI}}</td>
                    <td>{{item.ACIKLAMA}}</td>                   
                   <td>{{item.miktar | currency}}</td>
                    <td>{{item.yekun | currency}}</td>
                    <!-- <td>{{item.MALIYET | currency}}</td>  -->
                </tr>
            </tbody> 
        </table>
    </div>
</div> 
