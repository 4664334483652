<!doctype html>
<html lang="en" >

<head>
    <style>
        .input-style {
            background-color: transparent;
            color: #ffffff !important;
        }
    </style>

    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    <title>Cloud Report</title>
</head>

<body>
    <img src="../../../assets/images/login-bg.png" id="bg-main" alt="">
    <div style="margin-bottom: 11%;"> </div>
    <div class="container">
        <div class="row d-flex justify-content-center">
            <div
                class="col-lg-6 col-md-6 col-xl-6 col-sm-12 col-xs-12 d-flex justify-content-center main-content shadow" style="background-color: #ffffff;height: 500px;padding-top: 97px;">
                <div class="container">



                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-xl-12 col-sm-12 col-xs-12 d-flex justify-content-center">
                            <h4
                                style="text-align:center ;margin-bottom:10px;font-size: 30px;color:#475F7B;font-weight: bold;">
                                Giriş Yap</h4>
                        </div>
                    </div>

                    <div class="row" style="margin-bottom: 50px;">
                        <div class="col-lg-12 col-md-12 col-xl-12 col-sm-12 col-xs-12 d-flex justify-content-center">
                            <span style="color:#475F7B;">Tekrar hoş geldin ! Lütfen giriş yap veya kaydol</span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-xl-12 col-sm-12 col-xs-12 d-flex justify-content-center" style="padding-top: 20px;padding-bottom: 20px;">
                            <div class="d-flex justify-content-center">
                                <dx-validation-group>
                                    <div class="dx-field ">                                      
                                            <dx-text-box placeholder="Mail Adresi" [(value)]="mailAddress" width="350px"
                                                [showClearButton]="true" ></dx-text-box>
                                            <!-- <dx-validator>
                                                <dx-validator>
                                                    <dxi-validation-rule type="required" message="asda">
                                                    </dxi-validation-rule>
                                                </dx-validator>
                                            </dx-validator> -->
                                      
                                    </div>

                                    <div class="dx-field">                                      
                                            <dx-text-box width="350px" mode="password" [(value)]="password" placeholder="Parola"
                                                [showClearButton]="true"></dx-text-box>
                                            <!-- <dx-validator>
                                                <dx-validator>
                                                    <dxi-validation-rule type="required" message="asdas">
                                                    </dxi-validation-rule>
                                                </dx-validator>
                                            </dx-validator>                                     -->
                                    </div>
                           
                                    <div class="dx-field d-flex justify-content-center">
                                        <dx-button type="default"
                                            style="border-radius: 30px; font-size: 16px; background-color: #4C52BC;color:#ffffff;"
                                            text="Giriş Yap" width="350px" (onClick)="login($event)"></dx-button>
                                    </div>

                                    <div class="dx-field d-flex justify-content-center">
                                       <a (click)="forgetPassword()"><span style="color:#4C52BC;cursor: pointer;">Şifremi Unuttum</span></a>
                                    </div>
                                </dx-validation-group>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</body>

</html>


<dx-popup [height]="400" [width]="this.glb.setPopupWidth()" [showTitle]="true" [showCloseButton]="true"
    [dragEnabled]="false" [closeOnOutsideClick]="false" [(visible)]="resetPasswordPopupVisible" title="Şifremi Unuttum">
    <div *dxTemplate="let data of 'content'">
        <dx-scroll-view width="100%" height="100%">
            <app-reset-password [myCallback]="theBoundCallback"></app-reset-password>
        </dx-scroll-view>
    </div>
</dx-popup>


<dx-load-panel #loadPanel [message]="this.glb.loadingText" shadingColor="rgba(0,0,0,0.4)"
  [indicatorSrc]="this.glb.indicatorUrl" [position]="{ of: '#employee' }" [(visible)]="this.glb.loadingVisible"
  [showIndicator]="true" [showPane]="true" [shading]="true" [closeOnOutsideClick]="false">
</dx-load-panel>