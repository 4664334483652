<div class="row" style="margin-bottom: 20px;">
    <div class="col-12">
      <button (click)="exportExcel()" style="float: right;" type="button" class="btn btn-success">
        <span class="material-icons">
          get_app
          </span>
      </button>
      <button (click)="exportToPDF()" style="float: right; margin-right: 5px;" type="button" class="btn btn-primary">
        <span class="material-icons">
          picture_as_pdf
          </span>
      </button>
    </div>
  </div>

<div class="container shadow" style="margin-bottom: 30px;">
    <div class="col-12">
        <table class="table table-hover" style="margin-bottom: 20px;" id="contentToConvert">
            <thead>
                <tr>
                    <th scope="col" style="color:#4C52BC">Grup Türü</th>
                    <th scope="col" style="color:#4C52BC">İndirimsiz Brüt</th>
                    <th scope="col" style="color:#4C52BC">Brüt İndirim</th>
                    <th scope="col" style="color:#4C52BC">İndirimsiz Net</th>
                    <th scope="col" style="color:#4C52BC">Net İndirim</th>
                    <th scope="col" style="color:#4C52BC">İndirimli Net</th>
                    <th scope="col" style="color:#4C52BC">Ürün Adet</th>                          
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of this.rs.menuSaleReport;let c=index">
                    <td style="cursor: pointer!important;text-decoration: underline;"><a (click)="getSalesDetailReport(item.S_KODU,item.GRUPTURU)">{{item.GRUPTURU}}</a></td>
                    <td style="text-align: center;">{{item.INDIRIMSIZ_BRUT | currency}}</td>
                    <td style="text-align: center;">{{item.BRUT_INDIRIM | currency}}</td>
                    <td style="text-align: center;">{{item.INDIRIMSIZ_NET | currency}}</td>
                    <td style="text-align: center;">{{item.NET_INDIRIM | currency}}</td>
                    <td style="text-align: center;">{{item.INDIRIMLI_NET | currency}}</td>
                    <td>{{item.URUN_ADET | number}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div> 
