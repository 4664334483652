export class User {
    accessToken: string = ""
    id: string = ""
    licances: Licances[] = []
    mail: string = ""
    name: string = ""
}

export class Licances {
    allPermissions: []
    brand: string = ""
    id: string = ""
    licanceId: string = ""
    roles: Roles[] = []
    branchCodes: string[] = []
}


export class Roles {
    developer: boolean = false;
    id: string = ""
    level: number = 0
    namen: string = ""
}