import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ExcelService } from 'src/app/services/excel.service';
import { GlobalService } from 'src/app/services/global.service';
import { ReportService } from 'src/app/services/report.service';
import { jsPDF } from "jspdf";  
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-folio-detail-report',
  templateUrl: './folio-detail-report.component.html',
  styleUrls: ['./folio-detail-report.component.scss']
})
export class FolioDetailReportComponent implements OnInit {

  constructor(public glb : GlobalService,public rs : ReportService,private nav: Router,private exl : ExcelService) {
    
   }

  ngOnInit(): void {
  }


  exportToPDF()
{
  this.glb.loadingText = "PDF Aktarılıyor..."
  this.glb.loadingVisible = true;
  var data = document.getElementById('contentToConvert');  
   html2canvas(data).then(canvas => {  
    var imgData = canvas.toDataURL('image/png');
    var imgWidth = 210;
    var pageHeight = 295;
    var imgHeight = canvas.height * imgWidth / canvas.width;
    var heightLeft = imgHeight;
    
    var doc = new jsPDF('p', 'mm');
    var position = 0;
    
    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;
    
    while (heightLeft >= 0) {
    position = heightLeft - imgHeight;
    doc.addPage();
    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;
    }
    doc.save('AdisyonDetay.pdf');
    this.glb.loadingVisible = false;  
  });


}


  selectDetail(folioNo)
  {
    this.glb.loadingText = "Rapor Yükleniyor..."
    this.glb.loadingVisible = true;
    this.rs.getFolioDetail(folioNo).then(o => {
      console.log(o)
      debugger
      this.glb.loadingVisible = false;
      this.nav.navigateByUrl('layout/folio-detail')
    })
  }

}
