import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ExcelService } from 'src/app/services/excel.service';
import { GlobalService } from 'src/app/services/global.service';
import { ReportService } from 'src/app/services/report.service';
import { ExcelDataType } from 'xlsx/types';

import { jsPDF } from "jspdf";
  
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-sales-report',
  templateUrl: './sales-report.component.html',
  styleUrls: ['./sales-report.component.scss']
})
export class SalesReportComponent implements OnInit {

  constructor(public glb :GlobalService,
    public rs :ReportService,
    private nav: Router,private exl : ExcelService) { 
      this.rs.menuSaleReport
    }


    exportExcel()
    {
      this.glb.loadingText = "Excel'e Aktarılıyor..."
      this.glb.loadingVisible = true;
      setTimeout(() => {
        this.exl.exportAsExcelFile(this.rs.menuSaleReport,"Menü Satış Raporu")
        this.glb.loadingVisible = false;
        this.glb.pushToast('Excel Aktarımı Tamamlandı.','success',2000)
      }, 2000);
    }

    exportToPDF()
{

  this.glb.loadingText = "PDF Aktarılıyor..."
  this.glb.loadingVisible = true;
  var data = document.getElementById('contentToConvert');  
   html2canvas(data).then(canvas => {  
    var imgData = canvas.toDataURL('image/png');
    var imgWidth = 210;
    var pageHeight = 295;
    var imgHeight = canvas.height * imgWidth / canvas.width;
    var heightLeft = imgHeight;
    
    var doc = new jsPDF('p', 'mm');
    var position = 0;
    
    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;
    
    while (heightLeft >= 0) {
    position = heightLeft - imgHeight;
    doc.addPage();
    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;
    }
    doc.save('Menü Satış Raporu.pdf');
    this.glb.loadingVisible = false;  
  });
}

    getSalesDetailReport(branchCode,group)
    {
      this.glb.loadingText = "Rapor Yükleniyor..."
      this.glb.loadingVisible = true;
      this.glb.mainRequestData.branchCode = branchCode
      this.glb.mainRequestData.selectedGroup = group
      this.glb.mainRequestData.parameter = "group"
      this.rs.getGroupSalesReport().then(o => {       
        this.glb.loadingVisible = false;
        this.nav.navigateByUrl('/layout/operation-report/sales-report/group-sales-report')
      })
    }


  ngOnInit(): void {
  }

}
