<div class="row" style="margin-bottom: 20px;">
    <div class="col-12">
      <button (click)="exportExcel()" style="float: right;" type="button" class="btn btn-success">
        <span class="material-icons">
          get_app
          </span>
      </button>
      <button (click)="exportToPDF()" style="float: right; margin-right: 5px;" type="button" class="btn btn-primary">
        <span class="material-icons">
          picture_as_pdf
          </span>
      </button>
    </div>
  </div>


<div class="container shadow" style="margin-bottom: 30px;">
    <div class="col-12">
        <table class="table table-hover" style="margin-bottom: 20px;" id="contentToConvert">
            <thead>
                <tr>
                    <th style="color:#4C52BC" scope="col">Şube Adı</th>
                    <th style="color:#4C52BC" scope="col">İşlem Zamanı</th>
                    <th style="color:#4C52BC" scope="col">Kullancı</th>
                    <th style="color:#4C52BC" scope="col">Hedef Adisyon</th>
                    <th style="color:#4C52BC" scope="col">Hedef Masa</th>
                    <th style="color:#4C52BC" scope="col">Hedef Ürün Sayısı</th>
                    <th style="color:#4C52BC" scope="col">İptal Adisyon No</th>
                    <th style="color:#4C52BC" scope="col">İptal Masa</th>
                    <th style="color:#4C52BC" scope="col">İptal Ürün Sayısı</th>
                    <th style="color:#4C52BC" scope="col">Tip</th>
                    <th style="color:#4C52BC" scope="col">Masa Açan</th>
                    <th style="color:#4C52BC" scope="col">Kasiyer</th>
                    <th style="color:#4C52BC" scope="col">A Zaman</th>
                    <th style="color:#4C52BC" scope="col">K Zaman</th>
                  
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of this.rs.tableTransactionData;let c=index">
                    <td>{{item.SUBE_ADI}}</td>
                    <td>{{item.ISLEM_ZAMANI | date :  "dd.MM.yyyy h:mm"}}</td>
                    <td>{{item.KULLANICI}}</td>
                    <td><a (click) = "getFolioDetail(item.HEDEF_ADISYONNO)"><span style="text-decoration: underline;cursor: pointer;;">{{item.HEDEF_ADISYONNO}}</span></a></td>
                    <td>{{item.HEDEF_MASA}}</td>
                    <td>{{item.HEDEF_URUN_SAYI}}</td>
                    <td><a (click) = "getFolioDetail(item.IPTAL_ADISYONNO)"><span style="text-decoration: underline;cursor: pointer;;">{{item.IPTAL_ADISYONNO}}</span></a></td>
                    <td>{{item.IPTAL_MASA}}</td>
                    <td>{{item.IPTAL_URUN_SAYI}}</td>
                    <td>{{item.TIP}}</td>
                    <td>{{item.MASA_ACAN}}</td>
                    <td>{{item.KASIYER}}</td>
                    <td>{{item.A_ZAMAN | date :  "dd.MM.yyyy h:mm"}}</td>
                    <td>{{item.K_ZAMAN | date :  "dd.MM.yyyy h:mm"}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div> 
