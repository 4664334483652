import { Component, OnInit } from "@angular/core";
import { GlobalService } from "src/app/services/global.service";
import { ReportService } from "src/app/services/report.service";
import { exportDataGrid } from "devextreme/excel_exporter";

import { jsPDF } from "jspdf";

import html2canvas from "html2canvas";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";

@Component({
  selector: "app-sales-cost-report",
  templateUrl: "./sales-cost-report.component.html",
  styleUrls: ["./sales-cost-report.component.scss"],
})
export class SalesCostReportComponent implements OnInit {
  constructor(public glb: GlobalService, public rs: ReportService) {}

  exportToPDF() {
    this.glb.loadingText = "PDF Aktarılıyor...";
    this.glb.loadingVisible = true;
    var data = document.getElementById("contentToConvert");
    html2canvas(data).then((canvas) => {
      var imgData = canvas.toDataURL("image/png");
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;

      var doc = new jsPDF("p", "mm");
      var position = 0;

      doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      doc.save("Satış Maliyet Raporu.pdf");
      this.glb.loadingVisible = false;
    });
  }

  ngOnInit(): void {}

  calculatePercentCell(data) {
    return data.MALIYET_ORANI > 0 ? data.MALIYET_ORANI / 100 : 0;
  }

  calculateTotalMaliyet(options) {
    if (options.name === "allMaliyetColumn") {
      if (options.summaryProcess === "start") {
        options.totalValue = 0;
      } else if (options.summaryProcess === "calculate") {
        options.totalValue += +options.value.MALIYET * +options.value.MIKTAR;
      }
    }
  }

  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Main sheet");
    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      customizeCell: ({ gridCell, excelCell }) => {
        if (gridCell.rowType == "data") {
          if (gridCell.column.dataField == "MALIYET_ORANI") {
            excelCell.value = gridCell.value ? gridCell.value * 100 : 0;
          }
        }
      },
    }).then(function () {
      workbook.xlsx.writeBuffer().then(function (buffer: BlobPart) {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          "sales-report.xlsx"
        );
      });
    });
    e.cancel = true;
  }
}
