<div class="row" style="margin-bottom: 20px;">
    <div class="col-12">
      <button (click)="exportExcel()" style="float: right;" type="button" class="btn btn-success">
        <span class="material-icons">
          get_app
          </span>
      </button>
      <button (click)="exportToPDF()" style="float: right; margin-right: 5px;" type="button" class="btn btn-primary">
        <span class="material-icons">
          picture_as_pdf
          </span>
      </button>
    </div>
  </div>


<div class="container shadow" style="margin-bottom: 30px;">
    <div class="col-12">
        <table class="table table-hover" style="margin-bottom: 20px;" id="contentToConvert">
            <thead>
                <tr>                    
                    <th style="color:#4C52BC" scope="col">Tarih</th>
                    <th style="color:#4C52BC" scope="col">Sube</th>
                    <th style="color:#4C52BC" scope="col">Neden</th>
                    <th style="color:#4C52BC" scope="col">İkram Toplam</th>
                    <!-- <th style="color:#4C52BC" scope="col">İkram Maliyet</th>
                    <th style="color:#4C52BC" scope="col">Yekün</th>                   -->
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of this.rs.giftReportData;let c=index">
                    <td>{{item.TARIH}}</td>
                    <td>{{item.SUBE_ADI}}</td>
                    <td style="cursor: pointer!important;text-decoration: underline;"><a (click)="giftDetail(item)">{{item.NEDEN}}</a></td>                   
                    <td>{{item.IKRAM_TOPLAM | currency}}</td>
                    <!-- <td style="text-align: center;">{{item.IKRAM_MALIYET | currency}}</td>
                    <td style="text-align: center;">{{item.YEKUN | currency}}</td>                    -->
                </tr>
            </tbody>
        </table>
    </div>
</div> 
