import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ExcelService } from "src/app/services/excel.service";
import { GlobalService } from "src/app/services/global.service";
import { ReportService } from "src/app/services/report.service";
import { jsPDF } from "jspdf";

import html2canvas from "html2canvas";
import { RequestData } from "src/app/models/request-form-model";

@Component({
  selector: "app-gift-report",
  templateUrl: "./gift-report.component.html",
  styleUrls: ["./gift-report.component.scss"],
})
export class GiftReportComponent implements OnInit {
  constructor(
    public glb: GlobalService,
    public rs: ReportService,
    private nav: Router,
    private exl: ExcelService
  ) {}

  exportExcel() {
    this.glb.loadingText = "Excel'e Aktarılıyor...";
    this.glb.loadingVisible = true;
    setTimeout(() => {
      this.exl.exportAsExcelFile(this.rs.giftReportData, "İkram Raporu");
      this.glb.loadingVisible = false;
      this.glb.pushToast("Excel Aktarımı Tamamlandı.", "success", 2000);
    }, 2000);
  }

  exportToPDF() {
    this.glb.loadingText = "PDF Aktarılıyor...";
    this.glb.loadingVisible = true;
    var data = document.getElementById("contentToConvert");
    html2canvas(data).then((canvas) => {
      var imgData = canvas.toDataURL("image/png");
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;

      var doc = new jsPDF("p", "mm");
      var position = 0;

      doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      doc.save("İkram Raporu.pdf");
      this.glb.loadingVisible = false;
    });
  }

  giftDetail(reason) {
    debugger;
    console.log("my Gift Report:", reason);
    const params: RequestData = this.getFilterParams(reason);

    console.log("mainRequestData:", params);
    this.glb.loadingText = "Rapor Yükleniyor...";
    this.glb.loadingVisible = true;
    this.rs.getGiftDetailReportWidthParams(params).then((o) => {
      this.glb.loadingVisible = false;
      this.nav.navigateByUrl(
        "layout/operation-report/gift-report/gift-detail-report"
      );
    });
  }
  getFilterParams(reason: any): RequestData {
    const params: RequestData = { ...this.glb.mainRequestData };

    const dates: string[] = (reason.TARIH as string).split(".");
    const reasonDate: string = `${dates[1]}-${dates[0]}-${dates[2]}`;

    const sDate: Date = new Date(+dates[2], +dates[1] - 1, +dates[0]);
    const eDate: Date = new Date(+dates[2], +dates[1] - 1, +dates[0]);

    sDate.setHours(0, 0, 0, 0);
    eDate.setHours(23, 59, 59, 999);

    params.startedDate = sDate;
    params.endDate = eDate;

    return params;
  }

  ngOnInit(): void {}
}
