import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from './services/global.service';
import { ReportService } from './services/report.service';
import { UserService } from './services/user.service';
import config from 'devextreme/core/config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'cloud-report';


  constructor(
    private nav: Router,
    public glb: GlobalService,
    public us: UserService,
    public rs: ReportService
  ) {
    this.initializeApp();
    config({
      defaultCurrency: 'TRY',
    });
  }


  async initializeApp() {
    // debugger
    let _userInfo = JSON.parse(localStorage.getItem("credentials"))
    if (_userInfo != null) {
      this.glb.loadingText = "Yükleniyor..."
      this.glb.loadingVisible = true
      let _autologin = await this.us.autoLogin()
      if (_autologin) {
        setTimeout(() => {
          this.glb.loadingVisible = false;
          this.nav.navigateByUrl('layout')
          let activeLicance = localStorage.getItem('activeStore')
          if (localStorage.getItem('activeStore') != null) {
            // debugger           
            this.us.setActiveLicance(localStorage.getItem('activeStore'))
            this.rs.getBranchList()
            this.rs.getProductGroups()
            this.rs.getServiceTypes()
            this.rs.getAccountList();
            this.rs.getReasonTypes();
          }
          else {
            this.glb.licancePopupVisible = true;
          }
          this.glb.loadingVisible = false;
        }, 2000);
      }

    }
    else {
      this.glb.loadingVisible = false;
      this.nav.navigateByUrl('login')
    }
  }
}

