import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { ReportService } from 'src/app/services/report.service';

import { jsPDF } from "jspdf";
  
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-sales-report-by-service-type',
  templateUrl: './sales-report-by-service-type.component.html',
  styleUrls: ['./sales-report-by-service-type.component.scss']
})
export class SalesReportByServiceTypeComponent implements OnInit {
  isVisible : boolean = true;
  constructor(public glb: GlobalService,public rs: ReportService) { }

  ngOnInit(): void {
    
  }

  exportToPDF()
{

  this.glb.loadingText = "PDF Aktarılıyor..."
  this.glb.loadingVisible = true;
  var data = document.getElementById('contentToConvert');  
   html2canvas(data).then(canvas => {  
    var imgData = canvas.toDataURL('image/png');
    var imgWidth = 210;
    var pageHeight = 295;
    var imgHeight = canvas.height * imgWidth / canvas.width;
    var heightLeft = imgHeight;
    
    var doc = new jsPDF('p', 'mm');
    var position = 0;
    
    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;
    
    while (heightLeft >= 0) {
    position = heightLeft - imgHeight;
    doc.addPage();
    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;
    }
    doc.save('Servis Tiplerine Göre Satıs Raporu.pdf');
    this.glb.loadingVisible = false;  
  });
}


  checkVisible(fieldName)
  {
    if (this.glb.consiledType == 1)
    {
      if (fieldName == "GRUP" || fieldName == "BRUT_INDIRIM")
      {
        return false;
      }
      else
      {
        return true;
      }
    }
    else if (this.glb.consiledType == 2)
    {
      if (fieldName == "STOK_KODU" && fieldName == "isim" && fieldName == "GRUP" && fieldName == "GRUP" && fieldName == "ANA_GRUP" && fieldName == "GRUP_TURU" && fieldName == "BRUT_INDIRIM")
      {
        return false;
      }
    }
    else
    {
      return true;
    }
  }

}
