<!-- <ol class="breadcrumb">
  <li *ngFor="let breadcrumb of breadcrumbs">
    <span [routerLink]="breadcrumb.url" routerLinkActive="router-link-active">
      {{ breadcrumb.label }}
    </span>
  </li>
</ol> -->


<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li style="cursor: pointer;;" *ngFor="let breadcrumb of breadcrumbs">
      <span [routerLink]="breadcrumb.url" routerLinkActive="router-link-active">
        {{ breadcrumb.label }}
      </span>
    </li>
  </ol>
</nav>