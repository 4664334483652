import { Component, OnInit } from '@angular/core';



import { jsPDF } from "jspdf";

import html2canvas from 'html2canvas';
import { ReportService } from 'src/app/services/report.service';
import { UserService } from 'src/app/services/user.service';
import { GlobalService } from 'src/app/services/global.service';
import { Router } from '@angular/router';
import { ExcelService } from 'src/app/services/excel.service';

@Component({
  selector: 'app-payless-report',
  templateUrl: './payless-report.component.html',
  styleUrls: ['./payless-report.component.scss']
})
export class PaylessReportComponent implements OnInit {

  constructor(public rs: ReportService, public us: UserService, public glb: GlobalService, private nav: Router, private exl: ExcelService) { }


  exportExcel() {
    this.glb.loadingText = "Excel'e Aktarılıyor..."
    this.glb.loadingVisible = true;
    setTimeout(() => {
      this.exl.exportAsExcelFile(this.rs.operationReport, "Ödenmez Raporu")
      this.glb.loadingVisible = false;
      this.glb.pushToast('Excel Aktarımı Tamamlandı.', 'success', 2000)
    }, 2000);
  }

  exportToPDF() {
    this.glb.loadingText = "PDF Aktarılıyor..."
    this.glb.loadingVisible = true;
    var data = document.getElementById('contentToConvert');  
     html2canvas(data).then(canvas => {  
      var imgData = canvas.toDataURL('image/png');
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      
      var doc = new jsPDF('p', 'mm');
      var position = 0;
      
      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
      
      while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      doc.addPage();
      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
      }
      doc.save('Ödenmez Raporu.pdf');
      this.glb.loadingVisible = false;  
    });
  }

  getPaylessDetail() {
    this.glb.loadingText = "Rapor Yükleniyor..."
    this.glb.loadingVisible = true;
    this.rs.getPaylessDetailReport().then(o => {
      this.glb.loadingVisible = false;
      this.nav.navigateByUrl('layout/operation-report/payless-report/payless-detail-report')
    })
  }


  ngOnInit(): void {
  }

}
