<div class="row" style="margin-bottom: 20px;">
  <div class="col-12">
    <button (click)="exportExcel()" style="float: right;" type="button" class="btn btn-success">
      <span class="material-icons">
        get_app
        </span>
    </button>
     <button (click)="exportToPDF()" style="float: right; margin-right: 5px;" type="button" class="btn btn-primary">
      <span class="material-icons">
        picture_as_pdf
        </span>
    </button>
  </div>
</div>

<div class="row">
  <div class="col-12" id="contentToConvert">

<div *ngFor="let item of this.rs.operationReport;let i=index" class="container shadow" style="margin-bottom: 30px;" >
  <div class="row">
      <h4 style="color:#4C52BC;padding: 12px;">{{item.SUBE}}</h4>
      <div class="col-12">
          <table class="table table-hover" style="margin-bottom: 20px;">
              <thead>
                <tr>                    
                  <th style="color:#4C52BC;width: 48%;" scope="col">Brüt Gelir</th>
                  <th style="color:#4C52BC;width: 38%;" scope="col">{{item.BRUT_GELIR | currency}}</th>
                  <th  style="color:#4C52BC;" scope="col">100%</th>
                </tr>
              </thead>
              <tbody>                
                <tr>                   
                  <td>Net Satış</td>
                  <td >{{item.ODEME_TOPLAMI | currency}}</td>
                  <td >100%</td>
                </tr>
                <tr>                   
                  <td><a (click)="getDiscountReport(item.S_KODU)"><span style="text-decoration: underline;cursor: pointer;">İndirim</span></a></td>
                  <td >{{item.INDIRIM | currency}}</td>
                  <td >{{(item.INDIRIM / item.BRUT_GELIR)  * 100 | number}} % </td>                 
                </tr>

                <tr>                   
                  <td>KDV Dahil Net</td>
                  <td >{{item.KDV_DAHIL_NET | currency}}</td>
                  <td >{{(item.KDV_DAHIL_NET  / item.BRUT_GELIR) * 100 | number}} %</td>                          
                </tr>
                <tr>                   
                  <td><a (click)="menuSalesReport(item.S_KODU)"><span style="text-decoration: underline;cursor: pointer;">KDV Hariç Net</span></a></td>
                  <td >{{item.KDV_HARIC_NET | currency}}</td>
                  <td >{{(item.KDV_HARIC_NET  / item.BRUT_GELIR) * 100 | number}} %</td>
                </tr>
                <tr>                   
                  <td>KDV Tutarı</td>
                  <td >{{item.KDV_TUTARI | currency}}</td>
                  <td >{{item.KDV_DAHIL_NET  / item.BRUT_GELIR | number}} %</td>       
                </tr>
                <tr>                   
                  <td><a (click)="productFolioReport(item.S_KODU)" ><span style="text-decoration: underline;cursor: pointer;">Garsoniye</span></a></td>
                  <td >{{item.GARSONIYE | currency}}</td>
                  <td >{{(item.GARSONIYE / item.KDV_DAHIL_NET) *100 | number}} %</td>                  
                </tr>
                <tr>                   
                  <td><a (click)="paylessReport(item.S_KODU)" ><span style="text-decoration: underline;cursor: pointer;">Ödenmez</span></a></td>
                  <td >{{item.ODENMEZ | currency}}</td>
                  <td></td>                  
                </tr>
                <tr>                   
                  <td><a (click)="cancelledFolioReport(item.S_KODU)" ><span style="text-decoration: underline;cursor: pointer;">İptal Adisyon</span></a></td>
                  <td >{{item.IPTAL_TOPLAMI | currency}}</td>  
                  <td>{{item.IPTAL_ADET}}</td>                                 
                </tr>
              </tbody>
            </table>

            <table class="table table-hover" style="margin-bottom: 20px;">
              <thead>
                <tr>                    
                  <th style="color:#4C52BC;width: 48%;" scope="col">Operasyon Değerleri</th>
                  <th style="color:#4C52BC;width: 38%;" scope="col">Toplam</th>
                  <th style="color:#4C52BC;" scope="col">Servis(Brüt ve Net)</th>
                </tr>
              </thead>
              <tbody>               
                <tr>                   
                  <td>Kişi/Ort. Harcama</td>
                  <td>{{item.KISI}}</td>
                  <td style="text-align: right;" >{{item.AVG_KISI | currency}}</td>
                </tr>
                <tr>                   
                  <td>Çek/Ort. Harcama</td>
                  <td>{{item.ADISYON}}</td>
                  <td style="text-align: right;">{{item.AVG_ADISYON | currency}}</td>
                </tr>                
              </tbody>
            </table>

            <table class="table table-hover" style="margin-bottom: 20px;">
              <thead>
                <tr>                    
                  <th style="color:#4C52BC;width: 48%;" scope="col">Düzeltmeler</th>
                  <th style="color:#4C52BC;width: 38%;" scope="col">Toplam</th>
                  <th style="color:#4C52BC" scope="col">Ortalama</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let itemDetail of item.URUN_DUZENLEME;let c=index">                   
                  <td><a (click)="deletedItemReport(itemDetail.NEDEN,item.S_KODU)"><span style="text-decoration: underline;cursor: pointer;;">{{itemDetail.NEDEN}}</span></a></td>
                  <td>{{itemDetail.MIKTAR}}</td>
                  <td style="text-align: right;" >{{itemDetail.TOPLAM | currency}}</td>                    
                </tr>
                <tr>                   
                  <td> <a (click)="tableTransactionReport(item.S_KODU)"><span style="text-decoration: underline;cursor: pointer;">Masa Değiştirme/Birleştirme</span></a> </td>
                  <td></td>
                  <td></td>
                </tr>  
                <tr>                   
                  <td> <a (click)="giftReport(item.S_KODU)"><span style="text-decoration: underline;cursor: pointer;">İkram</span></a> </td>
                  <td></td>
                  <td></td>
                </tr>                                                 
              </tbody>
            </table> 
      </div>
  </div>
</div>
  </div>
</div>
