import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import jwt_decode from "jwt-decode";
import { Router } from '@angular/router';
import { Licances, User } from '../models/user-model';
import { ReportService } from './report.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  user: User = new User()
  activeLicance: Licances = new Licances()
  constructor(public glb: GlobalService, private http: HttpClient, private nav: Router) { }


  login(email, password): Promise<any> {
    return new Promise((resolve, reject) => {
      let httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'apiKey': this.glb.apiKey })
      }
      this.http.post(this.glb.userServiceUrl + '/api/user/login', { email: email, password: password }, httpOptions).subscribe(o => {
        console.log('login result', Object.assign(o))
        let returnVal: any = jwt_decode(Object.assign(o).token)
        this.user = returnVal.userInfo
        console.log('this.user', this.user)


        // this.name = this.userInfo.name
        // this.email = this.userInfo.mail
        // this.glb.login = true
        // this.authStatus = true

        let credentials = undefined
        credentials = JSON.stringify({ email: email, password: password })
        localStorage.setItem("credentials", credentials)
        resolve("ok")
      }, e => {
        console.log("error", e.error.message)
        if (e.error.message == "disallowed application") {
          this.glb.pushToast('Bu Modüle Giriş Yetkiniz Yok.', "error", 2000)
        }
        else if (e.error.message == "user not found") {
          this.glb.pushToast('Kullanıcı Bulunamadı', "error", 2000)
        }
        else if (e.error.message == 'password error') {
          this.glb.pushToast('Hatalı Parola Girnizi.', "error", 2000)
        }
        // this.glb.login = false
        // this.authStatus = false
        reject(e.error.message)
      })
    })
  }

  logout() {
    localStorage.setItem("credentials", undefined)
    this.nav.navigateByUrl('login')
  }

  resetPassword(eMail) : Promise<any> {
    return new Promise((resolve, reject)  => {
      debugger
      let httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'apiKey': this.glb.apiKey })
      }
      this.http.post(this.glb.userServiceUrl + '/api/user/resetpwd', { email:eMail }, httpOptions).subscribe(o => {
        console.log(o)
        resolve (Object.assign(o))
      }, e => {
        console.log("error", e.error.message)
        if (e.error.message == "user not found") {
          this.glb.pushToast('Kullanıcı Bulunamadı', "error", 2000)
        }       
        resolve (e.error.message)
      })
    })
 
  }



  setActiveLicance(id) {
    if (this.user.licances.length > 0) {
      this.activeLicance = this.user.licances.find(x => x.licanceId == id)
      localStorage.setItem('activeStore', id)
    }
  }

  autoLogin(): Promise<any> {

    let cred = localStorage.getItem("credentials")
    if (this.isJson(cred)) {
      let credentials = JSON.parse(cred)
      if (credentials) {
        console.log("credentials found, auto login", credentials)
        return this.login(credentials.email, credentials.password)
      } else {
        return new Promise((resolve, reject) => {
          console.log("credentials not found, auto login disabled")
          resolve("not-remember")
        })
      }
    } else {
      return new Promise((resolve, reject) => {
        console.log("credentials not found, auto login disabled")
        resolve("not-remember")
      })
    }
  }

  isJson(item) {
    item = typeof item !== "string"
      ? JSON.stringify(item)
      : item;
    try {
      item = JSON.parse(item);
    } catch (e) {
      return false;
    }
    if (typeof item === "object" && item !== null) {
      return true;
    }
    return false;
  }



}


