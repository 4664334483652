export class RequestData {
  startedDate;
  endDate: Date;
  branchCode;
  groupType;
  licanceNo;
  reason;
  parameter;
  selectedGroup;
  isSummary;
  isServiceFee;
  includeVariation;
  includeTax;
  selecetedProduct;
  serviceTypes;
  consolidated;
  invoiceType;
  recortType;
  accountCode;
  warehouse;
  param;
  stock_code;
  vatInclude;
  counting;
  isConsolidated;
  useType;
  reportType;
  reportLevel;
  Hammadde;
  Goruntulenme;
  reasonType;
}
