import { BrowserModule } from "@angular/platform-browser";
import { NgModule, LOCALE_ID } from "@angular/core";
import {
  DxDataGridModule,
  DxFormModule,
  DxTreeListModule,
  DxPopupModule,
  DxLoadPanelModule,
  DxDateBoxModule,
  DxTextBoxModule,
  DxListModule,
  DxActionSheetModule,
  DxValidatorModule,
  DxButtonModule,
  DxValidationGroupModule,
  DxSelectBoxModule,
  DxNumberBoxModule,
  DxSwitchModule,
  DxScrollViewModule,
  DxTextAreaModule,
  DxTagBoxModule,
  DxPivotGridModule,
} from "devextreme-angular";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./components/login/login.component";
import { RouterModule, Routes } from "@angular/router";
import { LayoutComponent } from "./components/layout/layout.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
  HttpInterceptor,
} from "@angular/common/http";
import { RequestFormComponent } from "./components/request-form/request-form.component";
import { LicanceListComponent } from "./components/licance-list/licance-list.component";
import { OperationReportComponent } from "./components/reports/operation-report/operation-report.component";
import { DiscountReportComponent } from "./components/reports/discount-report/discount-report.component";
import { DiscountDetailReportComponent } from "./components/reports/discount-detail-report/discount-detail-report.component";
import { FolioDetailReportComponent } from "./components/reports/folio-detail-report/folio-detail-report.component";
import { SalesReportComponent } from "./components/reports/sales-report/sales-report.component";
import { GroupSalesReportComponent } from "./components/reports/group-sales-report/group-sales-report.component";
import { ProductSalesReportComponent } from "./components/reports/product-sales-report/product-sales-report.component";
import { ProductFolioSalesReportComponent } from "./components/reports/product-folio-sales-report/product-folio-sales-report.component";
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { DeletedItemReportComponent } from "./components/reports/deleted-item-report/deleted-item-report.component";
import { TableTransactionReportComponent } from "./components/reports/table-transaction-report/table-transaction-report.component";
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";
import { ReportCurrencyPipe } from "./pipes/currency.pipe";
import { registerLocaleData } from "@angular/common";
import localeTr from "@angular/common/locales/tr";
import { GiftReportComponent } from "./components/reports/gift-report/gift-report.component";
import { GiftDetailReportComponent } from "./components/reports/gift-detail-report/gift-detail-report.component";
import { SalesCostReportComponent } from "./sales-cost-report/sales-cost-report.component";
import { SalesReportByServiceTypeComponent } from "./components/reports/sales-report-by-service-type/sales-report-by-service-type.component";
import { PaylessReportComponent } from "./components/reports/payless-report/payless-report.component";
import { PaylessDetailReportComponent } from "./components/reports/payless-detail-report/payless-detail-report.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { InvoiceReportComponent } from "./components/reports/invoice-report/invoice-report.component";
import { InventoryReportComponent } from "./components/reports/inventory-report/inventory-report.component";
import { CanceledFolioReportComponent } from './components/reports/canceled-folio-report/canceled-folio-report.component';
import { WastageStaffReportComponent } from "./components/reports/wastage-staff-report/wastage-staff-report.component";
import { BranchAnalysisReportComponent } from './components/reports/branch-analysis-report/branch-analysis-report.component';
import { VatTurnoverReportComponent } from './components/reports/vat-turnover-report/vat-turnover-report.component';

registerLocaleData(localeTr);

const appRoutes: Routes = [
  { path: "login", component: LoginComponent },
  // { path: 'sign-up', component: SignUpComponent },

  {
    path: "layout",
    data: {
      breadcrumb: "Ana Sayfa",
    },
    component: LayoutComponent,
    children: [
      {
        path: "operation-report",
        data: {
          breadcrumb: "Günlük Operasyon Raporu",
        },
        children: [
          {
            path: "",
            data: {
              breadcrumb: null,
            },
            component: OperationReportComponent,
          },
          {
            path: "discount-report",
            data: {
              breadcrumb: "İndirim Raporu",
            },
            children: [
              {
                path: "",
                data: {
                  breadcrumb: null,
                },
                component: DiscountReportComponent,
              },
              {
                path: "discount-detail-report",
                data: {
                  breadcrumb: "İndirim Detay Raporu",
                },
                children: [
                  {
                    path: "",
                    data: {
                      breadcrumb: null,
                    },
                    component: DiscountDetailReportComponent,
                  },
                  {
                    path: "folio-detail",
                    data: {
                      breadcrumb: "Adisyon Detay",
                    },
                    component: FolioDetailReportComponent,
                  },
                ],
              },
            ],
          },
          {
            path: "payless-report",
            data: {
              breadcrumb: "Ödenmez Raporu",
            },
            children: [
              {
                path: "",
                data: {
                  breadcrumb: null,
                },
                component: PaylessReportComponent,
              },
              {
                path: "payless-detail-report",
                data: {
                  breadcrumb: "Ödenmez Detay Raporu",
                },
                children: [
                  {
                    path: "",
                    data: {
                      breadcrumb: null,
                    },
                    component: PaylessDetailReportComponent,
                  },
                  {
                    path: "folio-detail",
                    data: {
                      breadcrumb: "Adisyon Detay",
                    },
                    component: FolioDetailReportComponent,
                  },
                ],
              },
            ],
          },
          {
            path: "sales-report",
            data: {
              breadcrumb: "Menü Satış Raporu",
            },
            children: [
              {
                path: "",
                data: {
                  breadcrumb: null,
                },
                component: SalesReportComponent,
              },
              {
                path: "group-sales-report",
                data: {
                  breadcrumb: "Gruplara Göre Satış Raporu",
                },
                children: [
                  {
                    path: "",
                    data: {
                      breadcrumb: null,
                    },
                    component: GroupSalesReportComponent,
                  },
                  {
                    path: "product-sales-report",
                    data: {
                      breadcrumb: "Ürünlere Göre Satış Raporu",
                    },
                    children: [
                      {
                        path: "",
                        data: {
                          breadcrumb: null,
                        },
                        component: ProductSalesReportComponent,
                      },
                      {
                        path: "product-folio-sales-report",
                        data: {
                          breadcrumb: "Ürün - Adisyon Satış Raporu",
                        },
                        children: [
                          {
                            path: "",
                            data: {
                              breadcrumb: null,
                            },
                            component: ProductFolioSalesReportComponent,
                          },
                          {
                            path: "folio-detail",
                            data: {
                              breadcrumb: "Adisyon Detay",
                            },
                            component: FolioDetailReportComponent,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: "deleted-item-report",
            data: {
              breadcrumb: "Silinen Ürün Raporu",
            },
            children: [
              {
                path: "",
                data: {
                  breadcrumb: null,
                },
                component: DeletedItemReportComponent,
              },
              {
                path: "folio-detail",
                data: {
                  breadcrumb: "Adisyon Detay",
                },
                component: FolioDetailReportComponent,
              },
            ],
          },
          {
            path: "table-transaction-report",
            data: {
              breadcrumb: "Masa Birleştirme/Değiştirme Reporu",
            },
            children: [
              {
                path: "",
                data: {
                  breadcrumb: null,
                },
                component: TableTransactionReportComponent,
              },
              {
                path: "folio-detail",
                data: {
                  breadcrumb: "Adisyon Detay",
                },
                component: FolioDetailReportComponent,
              },
            ],
          },
          {
            path: "cancelled-folio-report",
            data: {
              breadcrumb: "İptal Adisyon Raporu",
            },
            children: [
              {
                path: "",
                data: {
                  breadcrumb: null,
                },
                component: CanceledFolioReportComponent,
              },
              {
                path: "folio-detail",
                data: {
                  breadcrumb: "Adisyon Detay",
                },
                component: FolioDetailReportComponent,
              },
            ],
          },
          {
            path: "gift-report",
            data: {
              breadcrumb: "İkram Raporu",
            },
            children: [
              {
                path: "",
                data: {
                  breadcrumb: null,
                },
                component: GiftReportComponent,
              },
              {
                path: "gift-detail-report",
                data: {
                  breadcrumb: "İkram Detay Raporu",
                },
                children: [
                  {
                    path: "",
                    data: {
                      breadcrumb: null,
                    },
                    component: GiftDetailReportComponent,
                  },
                  {
                    path: "folio-detail",
                    data: {
                      breadcrumb: "Adisyon Detay",
                    },
                    component: FolioDetailReportComponent,
                  },
                ],
              },
            ],
          },
          {
            path: "product-folio-sales-report",
            data: {
              breadcrumb: "Garsoniye Raporu",
            },
            children: [
              {
                path: "",
                data: {
                  breadcrumb: null,
                },
                component: ProductFolioSalesReportComponent,
              },
              {
                path: "folio-detail",
                data: {
                  breadcrumb: "Adisyon Detay",
                },
                component: FolioDetailReportComponent,
              },
            ],
          },
        ],
      },
      {
        path: "cost-report",
        data: {
          breadcrumb: "Satış Maliyet Raporu",
        },
        children: [
          {
            path: "",
            data: {
              breadcrumb: null,
            },
            component: SalesCostReportComponent,
          },
        ],
      },
      {
        path: "wastage-staff-report",
        data: {
          breadcrumb: "Fire Zayi Personel Malzeme Çıkış Raporu",
        },
        children: [
          {
            path: "",
            data: {
              breadcrumb: null,
            },
            component: WastageStaffReportComponent,
          },
        ],
      },
      {
        path: "sales-report-service-type",
        data: {
          breadcrumb: "Servis Tipine Göre Satış Raporu",
        },
        children: [
          {
            path: "",
            data: {
              breadcrumb: null,
            },
            component: SalesReportByServiceTypeComponent,
          },
        ],
      },
      {
        path: "inventory-report",
        component: InventoryReportComponent,
      },
      {
        path: "vat-turnover-report",
        component: VatTurnoverReportComponent,
      },
      {
        path: "invoice-report",
        component: InvoiceReportComponent,
      },
      {
        path: "branch-analysis-report",
        component: BranchAnalysisReportComponent,
      },
      // { path: 'product-sales-report', component: ProductSalesReportComponent },
      // { path: 'product-folio-sales-report', component: ProductFolioSalesReportComponent },
      // { path: 'folio-detail', component: FolioDetailReportComponent },
      // { path: '', redirectTo: 'operation-report', pathMatch: 'full' },
    ],
  },

  // {
  //   path: 'operation-report', data: {
  //     breadcrumb: "Günlük Operasyon Reporu"
  //   }, component: OperationReportComponent,
  //   children: [
  //     {
  //       path: 'discount-report', data: {
  //         breadcrumb: "İndirim Raporu"
  //       }, component: DiscountReportComponent,
  //       children: [
  //         {
  //           path: 'discount-detail-report', data: {
  //             breadcrumb: "İndirim Detay Raporu"
  //           }, component: DiscountDetailReportComponent
  //         },
  //       ]
  //     },
  //   ]
  // },
];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LayoutComponent,
    SidebarComponent,
    RequestFormComponent,
    LicanceListComponent,
    OperationReportComponent,
    DiscountReportComponent,
    DiscountDetailReportComponent,
    FolioDetailReportComponent,
    SalesReportComponent,
    GroupSalesReportComponent,
    ProductSalesReportComponent,
    ProductFolioSalesReportComponent,
    BreadcrumbComponent,
    DeletedItemReportComponent,
    TableTransactionReportComponent,
    ResetPasswordComponent,
    ReportCurrencyPipe,
    GiftReportComponent,
    GiftDetailReportComponent,
    SalesCostReportComponent,
    SalesReportByServiceTypeComponent,
    PaylessReportComponent,
    PaylessDetailReportComponent,
    InvoiceReportComponent,
    InventoryReportComponent,
    CanceledFolioReportComponent,
    WastageStaffReportComponent,
    BranchAnalysisReportComponent,
    VatTurnoverReportComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    DxDataGridModule,
    DxFormModule,
    DxPopupModule,
    DxLoadPanelModule,
    DxTreeListModule,
    DxTextBoxModule,
    DxValidatorModule,
    DxActionSheetModule,
    DxPivotGridModule,
    DxDateBoxModule,
    DxButtonModule,
    DxValidationGroupModule,
    DxListModule,
    DxSelectBoxModule,
    DxNumberBoxModule,
    DxSwitchModule,
    DxScrollViewModule,
    DxTextAreaModule,
    DxTagBoxModule,
    RouterModule.forRoot(appRoutes, { enableTracing: false }),
  ],
  providers: [{ provide: LOCALE_ID, useValue: "tr-TR" }],
  bootstrap: [AppComponent],
})
export class AppModule {}
