import { CurrencyPipe } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { DxDataGridComponent } from "devextreme-angular";
import { exportDataGrid as exportDataGridToPdf } from "devextreme/pdf_exporter";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { ExcelService } from "src/app/services/excel.service";
import { GlobalService } from "src/app/services/global.service";
import { ReportService } from "src/app/services/report.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-inventory-report",
  templateUrl: "./inventory-report.component.html",
  styleUrls: ["./inventory-report.component.scss"],
  providers: [CurrencyPipe],
})
export class InventoryReportComponent implements OnInit {
  @ViewChild("dataGrid", { static: false }) dataGrid: DxDataGridComponent;

  constructor(
    public rs: ReportService,
    public us: UserService,
    public glb: GlobalService,
    private nav: Router,
    private exl: ExcelService,
    private currencyPipe: CurrencyPipe
  ) {
    console.log("ada", this.rs.paylessReportDetailData);
  }

  exportExcel() {
    this.glb.loadingText = "Excel'e Aktarılıyor...";
    this.glb.loadingVisible = true;
    setTimeout(() => {
      this.exl.exportAsExcelFile(this.rs.inventoryReport, "Envanter Raporu");
      this.glb.loadingVisible = false;
      this.glb.pushToast("Excel Aktarımı Tamamlandı.", "success", 2000);
    }, 2000);
  }

  customizeColumns(e: any[]) {
    // if (e.rowType === "data" && e.column.dataField === "FARK_MALIYET") {
    //   return this.currencyPipe.transform(e.data.FARK_MALIYET, 'TRY');
    // }
    e.forEach((elm, index) => {
      let hasMaliyet = (elm.dataField as string).includes("MALIYET");
      if (hasMaliyet) {
        e[index].format = { type: "currency", precision: 2 };
      }
    });
  }

  exportToPDF() {
    this.glb.loadingText = "PDF Aktarılıyor...";
    this.glb.loadingVisible = true;
    const doc = new jsPDF({
      orientation: "landscape",
    });
    doc.setFont("Times-Roman");
    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: this.dataGrid.instance,
    }).then(
      () => {
        doc.save("envanter-raporu-" + new Date().getTime() + ".pdf");
        this.glb.loadingVisible = false;
      },
      (err) => {
        this.glb.loadingVisible = false;
      }
    );
    // var data = document.getElementById("contentToConvert");
    // html2canvas(data).then((canvas) => {
    //   var imgData = canvas.toDataURL("image/png");
    //   var imgWidth = 210;
    //   var pageHeight = 295;
    //   var imgHeight = (canvas.height * imgWidth) / canvas.width;
    //   var heightLeft = imgHeight;

    //   var doc = new jsPDF("p", "mm");
    //   var position = 0;

    //   doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
    //   heightLeft -= pageHeight;

    //   while (heightLeft >= 0) {
    //     position = heightLeft - imgHeight;
    //     doc.addPage();
    //     doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
    //     heightLeft -= pageHeight;
    //   }
    //   doc.save("Envanter Raporu.pdf");
    //   this.glb.loadingVisible = false;
    // },err => {
    //   console.log(err);
    //   this.glb.loadingVisible = false;
    // });
  }

  ngOnInit(): void {}
}
