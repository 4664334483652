import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { RequestData } from "src/app/models/request-form-model";
import { Licances } from "src/app/models/user-model";
import { GlobalService } from "src/app/services/global.service";
import { ReportService } from "src/app/services/report.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-licance-list",
  templateUrl: "./licance-list.component.html",
  styleUrls: ["./licance-list.component.scss"],
})
export class LicanceListComponent implements OnInit {
  loadingText: string = "";
  loadingVisible: boolean = false;
  licanceList: Licances[] = [];
  constructor(
    private nav: Router,
    public us: UserService,
    public glb: GlobalService,
    public rs: ReportService
  ) {
    this.licanceList = this.us.user.licances;
  }

  async changeStore(storeId: string) {
    this.loadingText = "Bekleyiniz...";
    this.loadingVisible = true;
    this.us.setActiveLicance(storeId);
    this.glb.mainRequestData = {
      ...this.glb.mainRequestData,
      startedDate: new Date(),
      endDate: new Date(),
    } as RequestData;

      await Promise.all([
        this.rs.getBranchList(),
        this.rs.getProductGroups(),
        this.rs.getServiceTypes(),
        this.rs.getAccountList(),
      ]).then(
        (res) => {
          this.loadingVisible = false;
          this.glb.licancePopupVisible = false;
          this.nav.navigateByUrl("layout");
        },
        (err) => {
          this.loadingVisible = false;
          this.glb.licancePopupVisible = false;
          this.nav.navigateByUrl("layout");
        }
      );
  }

  searchStore(e) {
    debugger;
    if (e.value != "") {
      // let filterData = this.userService.user.store.filter(x=>x.id == e.value)

      this.licanceList = this.us.user.licances.filter((o) =>
        Object.keys(o).some((k) =>
          o.brand.toString().toLowerCase().includes(e.value.toLowerCase())
        )
      );
    } else {
      this.licanceList = this.us.user.licances;
    }
  }

  ngOnInit(): void {}
}
